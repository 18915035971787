import { Box, Paper, Switch, Typography } from "@mui/material";
import { useEffect } from "react";
import { Signal } from "@preact/signals-react";
import { useSignals } from "@preact/signals-react/runtime";
import ButtonOutlined from "../buttons/ButtonOutlined";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';

interface VariantsToProductProps {
  variants: Signal<variant[]>
}

interface variant {
  img_url: string
  option_names: (string | string[])[]
  price: number
  active: boolean
}

const VariantsToProduct: React.FC<VariantsToProductProps> = ({variants}) => {
  useSignals();

    useEffect(() => {

    }, [])

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
          <Box sx={{display: 'flex', px: '16px', gap: '36px'}}>
            <Typography variant='bodyMedium' sx={{color: 'grey'}}>Variant</Typography>
            <Typography variant='bodyMedium' sx={{color: 'grey', ml: 'auto'}}>Prijs</Typography>
            <Typography variant='bodyMedium' sx={{color: 'grey'}}>{variants.value.filter(v => v.active).length + '/' + variants.value.length} actief</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            {
              variants.value.map((variant, i) => 
                <Paper variant='outlined' sx={{display: 'flex', gap: '16px', p: '16px', alignItems: 'center'}}>
                  <img src={variant.img_url} alt='' width={'30px'} height={'30px'}/>
                  <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{variant.option_names.join(' / ')}</Typography>
                  <Box sx={{display: 'flex', gap: '4px', ml: 'auto'}}>
                    <EuroOutlinedIcon />
                    <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{variant.price.toFixed(2).toString().replaceAll('.', ',')}</Typography>
                  </Box>
                  <Switch checked={variant.active} onChange={() => {variants.value = variants.value.map((v, ii) => i === ii ? {...v, active: !variant.active} : {...v})}}/>
                </Paper>
              )
            }
          </Box>
          <ButtonOutlined Icon={ImageOutlinedIcon} text="Foto's aanpassen"  onClick={() => {}}/>
        </Box>
    );
  };

export default VariantsToProduct; 