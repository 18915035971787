import theme from '../../Theme'
import { ThemeProvider } from '@emotion/react';
import { Box,  FormControl,  InputLabel,  MenuItem,  Select,  Switch, TextField, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import ButtonFull from '../../components/buttons/ButtonFull';
import ButtonOutlined from '../../components/buttons/ButtonOutlined';
import Split from '../../components/layout/Split';
import ButtonBack from '../../components/buttons/ButtonBack';
import Card from '../../components/layout/Card';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { Signal } from '@preact/signals-react';
import { useEffect } from 'react';
import { apiClient } from '../../functions/Login';
import Confirm from '../../components/modals/Confirm';
import dayjs, { Dayjs } from 'dayjs';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ProductsToGroup from '../../components/products/ProductsToGroup';
import { ArtikelNaamOut, SalesProductGroupEdit, SalesProductGroupNaamOut } from '../../assets';
import { ConfigProvider, DatePicker, Upload, UploadFile } from 'antd';
import locale from 'antd/es/date-picker/locale/nl_NL';
import { UploadRequestError, UploadRequestOption } from "rc-upload/lib/interface";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

export default function ArticlesGroupDetails() {
    const navigate = useNavigate();
    const group_id = String(useParams().group_id);

    useSignals();
    // Define signals
    const openDeleteModal: Signal<boolean> = useSignal(false);
    const groups: Signal<SalesProductGroupNaamOut[]> = useSignal([]);
    const selectedGroup: Signal<string> = useSignal('');
    const group_name: Signal<string> = useSignal('');
    const group_code: Signal<string> = useSignal('');
    const activeFrom: Signal<Dayjs | null> = useSignal(null);
    const activeTill: Signal<Dayjs | null> = useSignal(null);
    const img_url: Signal<string> = useSignal('');
    const is_active: Signal<boolean> = useSignal(true);
    const selectedProducts: Signal<ArtikelNaamOut[]> = useSignal([]);

    const fileList: Signal<UploadFile[]> = useSignal([]);
    const uploadLogo = (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file} = options;

        apiClient().userManagement.uploadLogoToS3UsersUploadLogoImagePost({file: file as Blob}).then((r) => {
            if (onSuccess) onSuccess('OK');
            img_url.value = r;
        }).catch((error: UploadRequestError) => {
            if (onError) onError(error, 'Er is iets fout gegaan, probeer het opnieuw');
        })
    }
    
    const saveProductGroup = () => {
        const body = {
            sales_product_group_name: group_name.value,
            sales_product_group_code: group_code.value,
            sales_product_group_image: img_url.value,
            parent_id: selectedGroup.value === '' ? null: selectedGroup.value,
            is_active: is_active.value,
            active_from_date: activeFrom.value ? activeFrom.value.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            active_to_date: activeTill.value ? activeTill.value.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            products: selectedProducts.value.map(p => p.id)
        } as SalesProductGroupEdit
        apiClient().itemManagement.editSalesProductGroupItemsSalesProductGroupsSalesProductGroupIdPatch(group_id, body).then(() => {
            navigate('/artikelen/groepen');
        })
    }

    useEffect(() => {
        apiClient().itemManagement.getAllSalesProductGroupsItemsSalesProductGroupsGet().then((r) => {
            groups.value = r.items;
            groups.value = groups.value.filter(g => g.id !== group_id);
        })
        apiClient().itemManagement.getSalesProductGroupByIdItemsSalesProductGroupsSalesProductGroupIdGet(group_id).then((r) => {
            group_name.value = r.sales_product_group_name;
            group_code.value = r.sales_product_group_code;
            activeFrom.value = r.active_from_date ? dayjs(r.active_from_date) : null;
            activeTill.value = r.active_to_date ? dayjs(r.active_to_date) : null;
            selectedGroup.value = r.parent_id ? r.parent_id: '';
            img_url.value = r.sales_product_group_image ? r.sales_product_group_image: '';
            is_active.value = r.is_active ? r.is_active : true;

            if (img_url.value !== '') {
                fileList.value = [{
                    uid: '1',
                    name: 'articlegroup.png',
                    status: 'done',
                    url: img_url.value
                }]
            }

            apiClient().itemManagement.getAllProductsBySalesProductGroupIdItemsSalesProductGroupsSalesProductGroupIdAllProductsGet(group_id).then((r) => {
                selectedProducts.value = r.items
            })
        })
    }, [])

    return (
      <ThemeProvider theme={theme}>
        <Confirm 
            open={openDeleteModal} 
            func={() => {apiClient().itemManagement.deleteSalesProductGroupItemsSalesProductGroupsSalesProductGroupIdDelete(group_id).then(() => {navigate('/artikelen/groepen')})}}
            information_text='Door op bevestigen te drukken wordt deze artikelgroep permanent verwijdert.'
        />
        <Box sx={{mr: '16px', mb: '16px'}}>
            {/* Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '24px', gap: '8px'}}>
                <ButtonBack />

                <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                    {group_name.value}
                </Typography> 

                <ButtonOutlined Icon={DeleteOutlineOutlinedIcon} onClick={() => {openDeleteModal.value = true}} text='Artikelgroep verwijderen'/>
                <ButtonFull type='submit' form='productgroup' onClick={() => {}} text='Gegevens opslaan'/>
            </Box>

            {/* Body */}
            <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {e.preventDefault(); saveProductGroup()}} id='productgroup' style={{display: 'flex', gap: '16px', flexDirection: 'column'}}>
                <Split 
                    left={
                        <Card 
                            maxHeight='100%'
                            title='Groep' 
                            children={
                                <>
                                    <TextField required label="Naam groep" variant="outlined" value={group_name.value} onChange={(e) => {group_name.value = e.currentTarget.value}}/>
                                    <TextField required label="Groepscode" variant="outlined" value={group_code.value} onChange={(e) => {group_code.value = e.currentTarget.value}}/>
                                    <FormControl>
                                        <InputLabel id="group-select-label">Is subgroep van</InputLabel>
                                        <Select
                                            labelId='group-select-label'
                                            IconComponent={ExpandMoreOutlinedIcon}
                                            value={selectedGroup.value}
                                            label={'Is subgroep van'}
                                            MenuProps={{ style: {maxHeight: '300px'}}}
                                            onChange={(e) => {selectedGroup.value = e.target.value}}
                                            sx={{width: '100%', maxHeight: '60px', borderRadius: '4px'}}
                                        >
                                            <MenuItem value="">
                                                <em>Eigen groep</em>
                                            </MenuItem>
                                            {
                                                groups.value.map((group) => 
                                                    <MenuItem value={group.id} key={group.id}>
                                                        {group.sales_product_group_name}
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </>
                            }
                        />
                        
                    }
                    right={
                        <>
                            <Card 
                                title='Status' 
                                children={
                                    <>
                                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <Typography variant='bodyLarge'>
                                                De groep is {is_active.value ? 'actief' : 'inactief'}, gebruik de knop rechts om deze te {is_active.value ? 'deactiveren' : 'activeren'}.
                                            </Typography>
                                            <Switch onChange={() => {is_active.value = !is_active.value}} checked={is_active.value}/>
                                        </Box>
                                        <Typography variant='labelLarge' sx={{fontWeight: '700'}}>
                                            Periode actief
                                        </Typography>
                                        <Box sx={{display: 'flex', gap: '16px'}}>
                                            <ConfigProvider
                                                theme={{
                                                    components: {
                                                    DatePicker: {
                                                        activeBorderColor: '#28555e',
                                                        cellRangeBorderColor: '#28555e',
                                                        hoverBorderColor: '#28555e',
                                                        colorPrimary: '#28555e',
                                                        colorBorder: '#c4c4c4',
                                                        borderRadius: 4,
                                                        colorTextPlaceholder: '#000',
                                                        activeShadow: 'none',
                                                        presetsWidth: 360,
                                                        zIndexPopup: 2000,
                                                        zIndexPopupBase: 2000,
                                                        zIndexBase: 2000
                                                    },
                                                    },
                                                }}
                                            >
                                                <DatePicker required={(activeFrom.value != null || activeTill.value != null)} style={{width: '100%', height: '56px'}} placeholder='Actief van' onChange={(e) => {activeFrom.value = e}} value={activeFrom.value} format={'DD MMMM'} locale={locale} />
                                                <DatePicker required={(activeFrom.value != null || activeTill.value != null)} style={{width: '100%', height: '56px'}} placeholder='Actief tot' onChange={(e) => {activeTill.value = e}} value={activeTill.value} format={'DD MMMM'} locale={locale} />
                                            </ConfigProvider>
                                        </Box>
                                    </>
                                }
                            />
                            <Card 
                                title='Media' 
                                children={
                                    <>
                                        <Upload
                                            customRequest={(e) => {uploadLogo(e)}}
                                            listType="picture-card"
                                            fileList={fileList.value.length > 0 ? fileList.value : undefined}
                                            key={fileList.value.length}
                                        >
                                            {fileList.value.length > 0 ? null : <ButtonOutlined clickable={false} Icon={FileUploadOutlinedIcon} text='uploaden' onClick={() => {}}/>}
                                        </Upload>
                                    </>
                                }
                            />
                        </>
                    }
                />
                <Card 
                    title='Artikelen binnen groep' 
                    children={
                        <ProductsToGroup selectedProducts={selectedProducts}/>
                    }
                />
            </form>
        </Box>
      </ThemeProvider>
    );
  }