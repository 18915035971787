/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllergenOut } from '../models/AllergenOut';
import type { AllergenOutList } from '../models/AllergenOutList';
import type { ArtikelGroupDetailOut } from '../models/ArtikelGroupDetailOut';
import type { ArtikelNaamOutList } from '../models/ArtikelNaamOutList';
import type { Body_get_all_sales_products_items_sales_products_post } from '../models/Body_get_all_sales_products_items_sales_products_post';
import type { Body_get_product_option_categories_filtered_items_product_option_categories_post } from '../models/Body_get_product_option_categories_filtered_items_product_option_categories_post';
import type { Body_get_products_filtered_items_products_post } from '../models/Body_get_products_filtered_items_products_post';
import type { Body_get_sales_product_groups_filtered_items_sales_product_groups_post } from '../models/Body_get_sales_product_groups_filtered_items_sales_product_groups_post';
import type { Body_upload_file_to_s3_items_upload_file_post } from '../models/Body_upload_file_to_s3_items_upload_file_post';
import type { ComposedProductEdit } from '../models/ComposedProductEdit';
import type { ComposedProductIn } from '../models/ComposedProductIn';
import type { ComposedProductOut } from '../models/ComposedProductOut';
import type { DietaryFeatureOut } from '../models/DietaryFeatureOut';
import type { DietaryFeatureOutList } from '../models/DietaryFeatureOutList';
import type { Page_ArtikelGroupOverviewOut_ } from '../models/Page_ArtikelGroupOverviewOut_';
import type { Page_ArtikelOverviewOut_ } from '../models/Page_ArtikelOverviewOut_';
import type { Page_ProductOptionCategoryOverviewOut_ } from '../models/Page_ProductOptionCategoryOverviewOut_';
import type { Page_SalesProductGroupOut_ } from '../models/Page_SalesProductGroupOut_';
import type { Page_VariantActiveOut_ } from '../models/Page_VariantActiveOut_';
import type { Page_VariantOut_ } from '../models/Page_VariantOut_';
import type { Page_WeekdayVariantActiveOut_ } from '../models/Page_WeekdayVariantActiveOut_';
import type { ProductOptionCategoryEdit } from '../models/ProductOptionCategoryEdit';
import type { ProductOptionCategoryIn } from '../models/ProductOptionCategoryIn';
import type { ProductOptionCategoryNaamOutList } from '../models/ProductOptionCategoryNaamOutList';
import type { ProductOptionCategoryOut } from '../models/ProductOptionCategoryOut';
import type { ProductOptionCategoryReorder } from '../models/ProductOptionCategoryReorder';
import type { ProductOptionNaamOutList } from '../models/ProductOptionNaamOutList';
import type { SalesProductEdit } from '../models/SalesProductEdit';
import type { SalesProductGroupEdit } from '../models/SalesProductGroupEdit';
import type { SalesProductGroupIn } from '../models/SalesProductGroupIn';
import type { SalesProductGroupNaamOutList } from '../models/SalesProductGroupNaamOutList';
import type { SalesProductGroupOut } from '../models/SalesProductGroupOut';
import type { SalesProductIn } from '../models/SalesProductIn';
import type { SalesProductOut } from '../models/SalesProductOut';
import type { VariantActiveEdit } from '../models/VariantActiveEdit';
import type { VariantActiveIn } from '../models/VariantActiveIn';
import type { VariantActiveOut } from '../models/VariantActiveOut';
import type { VariantEdit } from '../models/VariantEdit';
import type { VariantNaamOutList } from '../models/VariantNaamOutList';
import type { VariantOut } from '../models/VariantOut';
import type { WeekdayVariantActiveEdit } from '../models/WeekdayVariantActiveEdit';
import type { WeekdayVariantActiveIn } from '../models/WeekdayVariantActiveIn';
import type { WeekdayVariantActiveOut } from '../models/WeekdayVariantActiveOut';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ItemManagementService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Unique Group Code
     * Check if group code is unique
     * @param code
     * @returns any Successful Response
     * @throws ApiError
     */
    public uniqueGroupCodeItemsUniqueGroupCodeCodeGet(
        code: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/unique_group_code/{code}',
            path: {
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unique Product Code
     * Check if product code is unique
     * @param code
     * @returns any Successful Response
     * @throws ApiError
     */
    public uniqueProductCodeItemsUniqueProductCodeCodeGet(
        code: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/unique_product_code/{code}',
            path: {
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unique Product Option Subcode
     * Check if product subcode is unique
     * @param code
     * @returns any Successful Response
     * @throws ApiError
     */
    public uniqueProductOptionSubcodeItemsUniqueProductOptionSubcodeCodeGet(
        code: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/unique_product_option_subcode/{code}',
            path: {
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Products
     * Get all Salesproducts and Composed products
     * @returns ArtikelNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllProductsItemsProductsGet(): CancelablePromise<ArtikelNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/products',
        });
    }

    /**
     * Get Products Filtered
     * Get all Salesproducts and Composed products filtered
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_ArtikelOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getProductsFilteredItemsProductsPost(
        search?: (string | null),
        sortingField?: 'Artikelnaam' | 'Variant' | 'Prijs' | 'Artikelcode' | 'Status',
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_products_filtered_items_products_post,
    ): CancelablePromise<Page_ArtikelOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/products',
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Sales Products
     * Get all sales products.
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_ArtikelOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getAllSalesProductsItemsSalesProductsPost(
        search?: (string | null),
        sortingField?: 'Artikelnaam' | 'Variant' | 'Prijs' | 'Artikelcode' | 'Status',
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_all_sales_products_items_sales_products_post,
    ): CancelablePromise<Page_ArtikelOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/sales_products',
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sales Product By Id
     * Get sales product by id.
     * @param salesProductId
     * @returns SalesProductOut Successful Response
     * @throws ApiError
     */
    public getSalesProductByIdItemsSalesProductsSalesProductIdGet(
        salesProductId: string,
    ): CancelablePromise<SalesProductOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/sales_products/{sales_product_id}',
            path: {
                'sales_product_id': salesProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Sales Product
     * Update a sales product by id.
     * @param salesProductId
     * @param requestBody
     * @returns SalesProductOut Successful Response
     * @throws ApiError
     */
    public editSalesProductItemsSalesProductsSalesProductIdPatch(
        salesProductId: string,
        requestBody: SalesProductEdit,
    ): CancelablePromise<SalesProductOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/items/sales_products/{sales_product_id}',
            path: {
                'sales_product_id': salesProductId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Sales Product
     * Delete a sales product by id.
     * @param salesProductId
     * @returns void
     * @throws ApiError
     */
    public deleteSalesProductItemsSalesProductsSalesProductIdDelete(
        salesProductId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/items/sales_products/{sales_product_id}',
            path: {
                'sales_product_id': salesProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Sales Product
     * Create new sales product.
     * @param requestBody
     * @returns SalesProductOut Successful Response
     * @throws ApiError
     */
    public createSalesProductItemsCreateSalesProductPost(
        requestBody: SalesProductIn,
    ): CancelablePromise<SalesProductOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/create_sales_product',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sales Product Groups By Sales Product Id
     * Get all sales product groups that a product is in.
     * @param salesProductId
     * @param page Page number
     * @param size Page size
     * @returns Page_SalesProductGroupOut_ Successful Response
     * @throws ApiError
     */
    public getSalesProductGroupsBySalesProductIdItemsSalesProductsSalesProductIdSalesProductGroupsGet(
        salesProductId: string,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_SalesProductGroupOut_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/sales_products/{sales_product_id}/sales_product_groups',
            path: {
                'sales_product_id': salesProductId,
            },
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Sales Product
     * Restore a sales product by id that was deleted before
     * @param salesProductId
     * @returns SalesProductOut Successful Response
     * @throws ApiError
     */
    public restoreSalesProductItemsSalesProductsSalesProductIdRestoreGet(
        salesProductId: string,
    ): CancelablePromise<SalesProductOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/sales_products/{sales_product_id}/restore',
            path: {
                'sales_product_id': salesProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Composed Product
     * Create new composed product.
     * @param requestBody
     * @returns ComposedProductOut Successful Response
     * @throws ApiError
     */
    public createComposedProductItemsCreateComposedProductPost(
        requestBody: ComposedProductIn,
    ): CancelablePromise<ComposedProductOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/create_composed_product',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Composed Product By Id
     * Get composed product by id.
     * @param composedProductId
     * @returns ComposedProductOut Successful Response
     * @throws ApiError
     */
    public getComposedProductByIdItemsComposedProductsComposedProductIdGet(
        composedProductId: string,
    ): CancelablePromise<ComposedProductOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/composed_products/{composed_product_id}',
            path: {
                'composed_product_id': composedProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Composed Product
     * Update a composed product by id.
     * @param composedProductId
     * @param requestBody
     * @returns ComposedProductOut Successful Response
     * @throws ApiError
     */
    public editComposedProductItemsComposedProductsComposedProductIdPatch(
        composedProductId: string,
        requestBody: ComposedProductEdit,
    ): CancelablePromise<ComposedProductOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/items/composed_products/{composed_product_id}',
            path: {
                'composed_product_id': composedProductId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Composed Product
     * Delete a composed product by id.
     * @param composedProductId
     * @returns void
     * @throws ApiError
     */
    public deleteComposedProductItemsComposedProductsComposedProductIdDelete(
        composedProductId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/items/composed_products/{composed_product_id}',
            path: {
                'composed_product_id': composedProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Composed Product
     * Restore a composed product by id that was deleted before
     * @param composedProductId
     * @returns ComposedProductOut Successful Response
     * @throws ApiError
     */
    public restoreComposedProductItemsComposedProductsComposedProductIdRestoreGet(
        composedProductId: string,
    ): CancelablePromise<ComposedProductOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/composed_products/{composed_product_id}/restore',
            path: {
                'composed_product_id': composedProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Sales Product Groups
     * Get all sales product groups.
     * @returns SalesProductGroupNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllSalesProductGroupsItemsSalesProductGroupsGet(): CancelablePromise<SalesProductGroupNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/sales_product_groups',
        });
    }

    /**
     * Get Sales Product Groups Filtered
     * Get sales product groups.
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_ArtikelGroupOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getSalesProductGroupsFilteredItemsSalesProductGroupsPost(
        search?: (string | null),
        sortingField?: 'Groepnaam' | 'Groepcode' | 'Periode_actief' | 'Subgroep_van' | 'Status',
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_sales_product_groups_filtered_items_sales_product_groups_post,
    ): CancelablePromise<Page_ArtikelGroupOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/sales_product_groups',
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sales Product Group By Id
     * Get sales product group by id.
     * @param salesProductGroupId
     * @returns ArtikelGroupDetailOut Successful Response
     * @throws ApiError
     */
    public getSalesProductGroupByIdItemsSalesProductGroupsSalesProductGroupIdGet(
        salesProductGroupId: string,
    ): CancelablePromise<ArtikelGroupDetailOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/sales_product_groups/{sales_product_group_id}',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Sales Product Group
     * Update a sales product group by id.
     * @param salesProductGroupId
     * @param requestBody
     * @returns SalesProductGroupOut Successful Response
     * @throws ApiError
     */
    public editSalesProductGroupItemsSalesProductGroupsSalesProductGroupIdPatch(
        salesProductGroupId: string,
        requestBody: SalesProductGroupEdit,
    ): CancelablePromise<SalesProductGroupOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/items/sales_product_groups/{sales_product_group_id}',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Sales Product Group
     * Delete a sales product group by id.
     * @param salesProductGroupId
     * @returns void
     * @throws ApiError
     */
    public deleteSalesProductGroupItemsSalesProductGroupsSalesProductGroupIdDelete(
        salesProductGroupId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/items/sales_product_groups/{sales_product_group_id}',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Products By Sales Product Group Id
     * Get all sales products in a specific group.
     * @param salesProductGroupId
     * @returns ArtikelNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllProductsBySalesProductGroupIdItemsSalesProductGroupsSalesProductGroupIdAllProductsGet(
        salesProductGroupId: string,
    ): CancelablePromise<ArtikelNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/sales_product_groups/{sales_product_group_id}/all_products',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Sales Product Group
     * Create new sales product group.
     * @param requestBody
     * @returns SalesProductGroupOut Successful Response
     * @throws ApiError
     */
    public createSalesProductGroupItemsCreateSalesProductGroupPost(
        requestBody: SalesProductGroupIn,
    ): CancelablePromise<SalesProductGroupOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/create_sales_product_group',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Sales Products To Sales Product Group
     * Add a sales product to a sales product group.
     * @param salesProductGroupId
     * @param requestBody
     * @returns SalesProductGroupOut Successful Response
     * @throws ApiError
     */
    public addSalesProductsToSalesProductGroupItemsSalesProductGroupsSalesProductGroupIdAddSalesProductsPost(
        salesProductGroupId: string,
        requestBody: Array<string>,
    ): CancelablePromise<SalesProductGroupOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/sales_product_groups/{sales_product_group_id}/add/sales_products',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Sales Products From Sales Product Group
     * Remove a sales product to a sales product group.
     * @param salesProductGroupId
     * @param requestBody
     * @returns SalesProductGroupOut Successful Response
     * @throws ApiError
     */
    public removeSalesProductsFromSalesProductGroupItemsSalesProductGroupsSalesProductGroupIdRemoveSalesProductsPost(
        salesProductGroupId: string,
        requestBody: Array<string>,
    ): CancelablePromise<SalesProductGroupOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/sales_product_groups/{sales_product_group_id}/remove/sales_products',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Sales Product Group
     * Restore a sales product group by id that was deleted before
     * @param salesProductGroupId
     * @returns SalesProductGroupOut Successful Response
     * @throws ApiError
     */
    public restoreSalesProductGroupItemsSalesProductGroupsSalesProductGroupIdRestoreGet(
        salesProductGroupId: string,
    ): CancelablePromise<SalesProductGroupOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/sales_product_groups/{sales_product_group_id}/restore',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Product Options
     * Get all product options.
     * @returns ProductOptionNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllProductOptionsItemsProductOptionsGet(): CancelablePromise<ProductOptionNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/product_options',
        });
    }

    /**
     * Get All Product Option Categories
     * Get all product option categories.
     * @returns ProductOptionCategoryNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllProductOptionCategoriesItemsProductOptionCategoriesGet(): CancelablePromise<ProductOptionCategoryNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/product_option_categories',
        });
    }

    /**
     * Get Product Option Categories Filtered
     * Get product option categories filtered.
     * @param search
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_ProductOptionCategoryOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getProductOptionCategoriesFilteredItemsProductOptionCategoriesPost(
        search?: (string | null),
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_product_option_categories_filtered_items_product_option_categories_post,
    ): CancelablePromise<Page_ProductOptionCategoryOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/product_option_categories',
            query: {
                'search': search,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reorder Product Option Categories
     * Reorder product option categories and return full list.
     * @param requestBody
     * @param page Page number
     * @param size Page size
     * @returns Page_ProductOptionCategoryOverviewOut_ Successful Response
     * @throws ApiError
     */
    public reorderProductOptionCategoriesItemsReorderProductOptionCategoriesPost(
        requestBody: Array<ProductOptionCategoryReorder>,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_ProductOptionCategoryOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/reorder_product_option_categories',
            query: {
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Product Option Category By Id
     * Get product option category by id.
     * @param productOptionCategoryId
     * @returns ProductOptionCategoryOut Successful Response
     * @throws ApiError
     */
    public getProductOptionCategoryByIdItemsProductOptionCategoriesProductOptionCategoryIdGet(
        productOptionCategoryId: string,
    ): CancelablePromise<ProductOptionCategoryOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/product_option_categories/{product_option_category_id}',
            path: {
                'product_option_category_id': productOptionCategoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Product Option Category
     * Update a product option category by id.
     * @param productOptionCategoryId
     * @param requestBody
     * @returns ProductOptionCategoryOut Successful Response
     * @throws ApiError
     */
    public editProductOptionCategoryItemsProductOptionCategoriesProductOptionCategoryIdPatch(
        productOptionCategoryId: string,
        requestBody: ProductOptionCategoryEdit,
    ): CancelablePromise<ProductOptionCategoryOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/items/product_option_categories/{product_option_category_id}',
            path: {
                'product_option_category_id': productOptionCategoryId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Product Option Category
     * Delete a product option category by id.
     * @param productOptionCategoryId
     * @returns void
     * @throws ApiError
     */
    public deleteProductOptionCategoryItemsProductOptionCategoriesProductOptionCategoryIdDelete(
        productOptionCategoryId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/items/product_option_categories/{product_option_category_id}',
            path: {
                'product_option_category_id': productOptionCategoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Product Option Category
     * Create new product option category.
     * @param requestBody
     * @returns ProductOptionCategoryOut Successful Response
     * @throws ApiError
     */
    public createProductOptionCategoryItemsCreateProductOptionCategoryPost(
        requestBody: ProductOptionCategoryIn,
    ): CancelablePromise<ProductOptionCategoryOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/create_product_option_category',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Product Option Category
     * Restore a product option category by id that was deleted before
     * @param productOptionCategoryId
     * @returns ProductOptionCategoryOut Successful Response
     * @throws ApiError
     */
    public restoreProductOptionCategoryItemsProductOptionCategoriesProductOptionCategoryIdRestoreGet(
        productOptionCategoryId: string,
    ): CancelablePromise<ProductOptionCategoryOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/product_option_categories/{product_option_category_id}/restore',
            path: {
                'product_option_category_id': productOptionCategoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Variants
     * Get all variants.
     * @param page Page number
     * @param size Page size
     * @returns Page_VariantOut_ Successful Response
     * @throws ApiError
     */
    public getAllVariantsItemsVariantsGet(
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_VariantOut_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/variants',
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Variants
     * Get all variant names with product option names
     * @returns VariantNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllVariantsItemsAllVariantsGet(): CancelablePromise<VariantNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/all_variants',
        });
    }

    /**
     * Get Variant By Id
     * Get variant by id.
     * @param variantId
     * @returns VariantOut Successful Response
     * @throws ApiError
     */
    public getVariantByIdItemsVariantsVariantIdGet(
        variantId: string,
    ): CancelablePromise<VariantOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/variants/{variant_id}',
            path: {
                'variant_id': variantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Variant
     * Update a variant by id.
     * @param variantId
     * @param requestBody
     * @returns VariantOut Successful Response
     * @throws ApiError
     */
    public editVariantItemsVariantsVariantIdPatch(
        variantId: string,
        requestBody: VariantEdit,
    ): CancelablePromise<VariantOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/items/variants/{variant_id}',
            path: {
                'variant_id': variantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Variant Actives
     * Get all variant actives.
     * @param page Page number
     * @param size Page size
     * @returns Page_VariantActiveOut_ Successful Response
     * @throws ApiError
     */
    public getAllVariantActivesItemsVariantActivesGet(
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_VariantActiveOut_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/variant_actives',
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Variant Active
     * Create new variant active.
     * @param requestBody
     * @returns VariantActiveOut Successful Response
     * @throws ApiError
     */
    public createVariantActiveItemsVariantActivesPost(
        requestBody: VariantActiveIn,
    ): CancelablePromise<VariantActiveOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/variant_actives',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Variant Active By Id
     * Get variant active by id.
     * @param variantActiveId
     * @returns VariantActiveOut Successful Response
     * @throws ApiError
     */
    public getVariantActiveByIdItemsVariantActivesVariantActiveIdGet(
        variantActiveId: string,
    ): CancelablePromise<VariantActiveOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/variant_actives/{variant_active_id}',
            path: {
                'variant_active_id': variantActiveId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Variant Active
     * Update a spo active by id.
     * @param variantActiveId
     * @param requestBody
     * @returns VariantActiveOut Successful Response
     * @throws ApiError
     */
    public editVariantActiveItemsVariantActivesVariantActiveIdPatch(
        variantActiveId: string,
        requestBody: VariantActiveEdit,
    ): CancelablePromise<VariantActiveOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/items/variant_actives/{variant_active_id}',
            path: {
                'variant_active_id': variantActiveId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Variant Active
     * Delete a spo active by id.
     * @param variantActiveId
     * @returns void
     * @throws ApiError
     */
    public deleteVariantActiveItemsVariantActivesVariantActiveIdDelete(
        variantActiveId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/items/variant_actives/{variant_active_id}',
            path: {
                'variant_active_id': variantActiveId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Variant Active
     * Restore a variant active by id that was deleted before
     * @param variantActiveId
     * @returns VariantActiveOut Successful Response
     * @throws ApiError
     */
    public restoreVariantActiveItemsVariantActivesVariantActiveIdRestoreGet(
        variantActiveId: string,
    ): CancelablePromise<VariantActiveOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/variant_actives/{variant_active_id}/restore',
            path: {
                'variant_active_id': variantActiveId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refresh Variant Active Price
     * Refresh default prices for a variant active
     * @param variantActiveId
     * @returns VariantActiveOut Successful Response
     * @throws ApiError
     */
    public refreshVariantActivePriceItemsVariantActivesVariantActiveIdRefreshDefaultPricesGet(
        variantActiveId: string,
    ): CancelablePromise<VariantActiveOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/variant_actives/{variant_active_id}/refresh_default_prices',
            path: {
                'variant_active_id': variantActiveId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Weekday Variant Actives
     * Get all weekday variant actives.
     * @param page Page number
     * @param size Page size
     * @returns Page_WeekdayVariantActiveOut_ Successful Response
     * @throws ApiError
     */
    public getAllWeekdayVariantActivesItemsWeekdayVariantActivesGet(
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_WeekdayVariantActiveOut_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/weekday_variant_actives',
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Weekday Variant Active
     * Create new weekday variant active.
     * @param requestBody
     * @returns WeekdayVariantActiveOut Successful Response
     * @throws ApiError
     */
    public createWeekdayVariantActiveItemsWeekdayVariantActivesPost(
        requestBody: WeekdayVariantActiveIn,
    ): CancelablePromise<WeekdayVariantActiveOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/weekday_variant_actives',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Weekday Variant Active By Id
     * Get weekday variant active by id.
     * @param weekdayVariantActiveId
     * @returns WeekdayVariantActiveOut Successful Response
     * @throws ApiError
     */
    public getWeekdayVariantActiveByIdItemsWeekdayVariantActivesWeekdayVariantActiveIdGet(
        weekdayVariantActiveId: string,
    ): CancelablePromise<WeekdayVariantActiveOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/weekday_variant_actives/{weekday_variant_active_id}',
            path: {
                'weekday_variant_active_id': weekdayVariantActiveId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Weekday Variant Active
     * Update a weekday variant active by id.
     * @param weekdayVariantActiveId
     * @param requestBody
     * @returns WeekdayVariantActiveOut Successful Response
     * @throws ApiError
     */
    public editWeekdayVariantActiveItemsWeekdayVariantActivesWeekdayVariantActiveIdPatch(
        weekdayVariantActiveId: string,
        requestBody: WeekdayVariantActiveEdit,
    ): CancelablePromise<WeekdayVariantActiveOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/items/weekday_variant_actives/{weekday_variant_active_id}',
            path: {
                'weekday_variant_active_id': weekdayVariantActiveId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload File To S3
     * Upload a file to a (private or public) S3 bucket
     * @param formData
     * @param _public
     * @returns any Successful Response
     * @throws ApiError
     */
    public uploadFileToS3ItemsUploadFilePost(
        formData: Body_upload_file_to_s3_items_upload_file_post,
        _public: boolean = true,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/upload_file',
            query: {
                'public': _public,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Allergens
     * Get all allergens.
     * @returns AllergenOutList Successful Response
     * @throws ApiError
     */
    public getAllAllergensItemsAllergensGet(): CancelablePromise<AllergenOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/allergens',
        });
    }

    /**
     * Get Allergen By Id
     * Get allergen by id.
     * @param allergenId
     * @returns AllergenOut Successful Response
     * @throws ApiError
     */
    public getAllergenByIdItemsAllergensAllergenIdGet(
        allergenId: string,
    ): CancelablePromise<AllergenOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/allergens/{allergen_id}',
            path: {
                'allergen_id': allergenId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Dietaryfeatures
     * Get all dietaryfeatures.
     * @returns DietaryFeatureOutList Successful Response
     * @throws ApiError
     */
    public getAllDietaryfeaturesItemsDietaryfeaturesGet(): CancelablePromise<DietaryFeatureOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/dietaryfeatures',
        });
    }

    /**
     * Get Dietaryfeature By Id
     * Get dietaryfeature by id.
     * @param dietId
     * @returns DietaryFeatureOut Successful Response
     * @throws ApiError
     */
    public getDietaryfeatureByIdItemsDietaryfeaturesDietIdGet(
        dietId: string,
    ): CancelablePromise<DietaryFeatureOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/dietaryfeatures/{diet_id}',
            path: {
                'diet_id': dietId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
