import { Box,ThemeProvider } from "@mui/material";
import theme from "../../Theme";
import {  useSignals } from "@preact/signals-react/runtime";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/table/CustomTable";
import { apiClient } from "../../functions/Login";
import { CancelablePromise } from "../../assets";
import { useEffect } from "react";
import { Signal } from "@preact/signals-react";

interface props {
    groups: Signal<string[]>
    options: Signal<string[]>
    allergens: Signal<string[]>
    status: Signal<string>
}

const ArticlesOverview: React.FC<props> = ({groups, options, allergens, status}) => {
    useSignals();
    
    const navigate = useNavigate();

    useEffect(() => {

    }, [])
    
    return(
        <ThemeProvider theme={theme}>
            {/* Article groups Overview */}
            
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                <CustomTable 
                    dataEndpoint={(...params: any[]): CancelablePromise<any> => {return apiClient().itemManagement.getProductsFilteredItemsProductsPost(...params)}}
                    onClickLink='/artikelen/overzicht/bewerk/'
                    requestBody={
                        {
                            groups_ids: groups.value ? groups.value : null,
                            status: status.value ? status.value : null,
                            option_ids: options.value ? options.value : null,
                            allergens: allergens.value ? allergens.value : null
                        }
                    }
                />
            
            </Box>

        </ThemeProvider>
    )
}

export default ArticlesOverview;