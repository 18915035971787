/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressEdit } from '../models/AddressEdit';
import type { AddressIn } from '../models/AddressIn';
import type { AddressOut } from '../models/AddressOut';
import type { Body_create_subsidiary_users_create_subsidiary_post } from '../models/Body_create_subsidiary_users_create_subsidiary_post';
import type { Body_get_all_customers_users_customers_post } from '../models/Body_get_all_customers_users_customers_post';
import type { Body_get_all_employees_users_employees_post } from '../models/Body_get_all_employees_users_employees_post';
import type { Body_get_all_subsidiaries_users_subsidiaries_post } from '../models/Body_get_all_subsidiaries_users_subsidiaries_post';
import type { Body_update_subsidiary_users_subsidiary__company_id__patch } from '../models/Body_update_subsidiary_users_subsidiary__company_id__patch';
import type { Body_upload_logo_to_s3_users_upload_logo_image_post } from '../models/Body_upload_logo_to_s3_users_upload_logo_image_post';
import type { CompanyContactEdit } from '../models/CompanyContactEdit';
import type { CompanyDetailEdit } from '../models/CompanyDetailEdit';
import type { CompanyEdit } from '../models/CompanyEdit';
import type { CompanyOut } from '../models/CompanyOut';
import type { CompanyOutList } from '../models/CompanyOutList';
import type { CustomerAddress } from '../models/CustomerAddress';
import type { CustomerMetricOut } from '../models/CustomerMetricOut';
import type { CustomerOut } from '../models/CustomerOut';
import type { EmployeeOut } from '../models/EmployeeOut';
import type { KlantDetailEdit } from '../models/KlantDetailEdit';
import type { LocationCategoryOutList } from '../models/LocationCategoryOutList';
import type { LocationIn } from '../models/LocationIn';
import type { LocationNaamOutList } from '../models/LocationNaamOutList';
import type { LocationOut } from '../models/LocationOut';
import type { LocationOutList } from '../models/LocationOutList';
import type { LocationSpecialDayOutList } from '../models/LocationSpecialDayOutList';
import type { LocationWeekDayOutList } from '../models/LocationWeekDayOutList';
import type { MedewerkerDetailEdit } from '../models/MedewerkerDetailEdit';
import type { MedewerkerNaamOutList } from '../models/MedewerkerNaamOutList';
import type { MollieOrganizationOut } from '../models/MollieOrganizationOut';
import type { Page_BestelAccountOut_ } from '../models/Page_BestelAccountOut_';
import type { Page_CustomerDetailOrderOut_ } from '../models/Page_CustomerDetailOrderOut_';
import type { Page_KlantenOverzichtOut_ } from '../models/Page_KlantenOverzichtOut_';
import type { Page_LocationOverviewOut_ } from '../models/Page_LocationOverviewOut_';
import type { Page_MedewerkerOverzichtOut_ } from '../models/Page_MedewerkerOverzichtOut_';
import type { SubsidiaryOut } from '../models/SubsidiaryOut';
import type { SubsidiaryOverviewOut } from '../models/SubsidiaryOverviewOut';
import type { TenantStyleEdit } from '../models/TenantStyleEdit';
import type { TenantStyleOut } from '../models/TenantStyleOut';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserManagementService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Companies
     * @returns CompanyOutList Successful Response
     * @throws ApiError
     */
    public getAllCompaniesUsersCompaniesGet(): CancelablePromise<CompanyOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/companies',
        });
    }

    /**
     * Get Company By Id
     * Get a company by ID
     * @param companyId
     * @returns CompanyOut Successful Response
     * @throws ApiError
     */
    public getCompanyByIdUsersCompaniesCompanyIdGet(
        companyId: string,
    ): CancelablePromise<CompanyOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/companies/{company_id}',
            path: {
                'company_id': companyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Company
     * Edit a company
     * @param companyId
     * @param requestBody
     * @returns CompanyOut Successful Response
     * @throws ApiError
     */
    public updateCompanyUsersCompaniesCompanyIdPatch(
        companyId: string,
        requestBody: CompanyEdit,
    ): CancelablePromise<CompanyOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/companies/{company_id}',
            path: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Company
     * Delete a company
     * @param companyId
     * @returns void
     * @throws ApiError
     */
    public deleteCompanyUsersCompaniesCompanyIdDelete(
        companyId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/companies/{company_id}',
            path: {
                'company_id': companyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Ultimate Company Location
     * Get the ultimate company
     * @returns CompanyOut Successful Response
     * @throws ApiError
     */
    public getUltimateCompanyLocationUsersUltimateCompanyGet(): CancelablePromise<CompanyOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/ultimate_company',
        });
    }

    /**
     * Get Ultimate Company Location
     * Get the ultimate company mollie account connection info
     * @returns MollieOrganizationOut Successful Response
     * @throws ApiError
     */
    public getUltimateCompanyLocationUsersUltimateCompanyMollieGet(): CancelablePromise<MollieOrganizationOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/ultimate_company_mollie',
        });
    }

    /**
     * Update Company Delivery Address
     * Edit company delivery address
     * @param companyId
     * @param requestBody
     * @returns AddressOut Successful Response
     * @throws ApiError
     */
    public updateCompanyDeliveryAddressUsersCompaniesCompanyIdDeliveryAddressPatch(
        companyId: string,
        requestBody: AddressEdit,
    ): CancelablePromise<AddressOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/companies/{company_id}/delivery_address',
            path: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Company Delivery Address
     * @param companyId
     * @param requestBody
     * @returns AddressOut Successful Response
     * @throws ApiError
     */
    public createCompanyDeliveryAddressUsersCompaniesCompanyIdDeliveryAddressPost(
        companyId: string,
        requestBody: AddressIn,
    ): CancelablePromise<AddressOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/companies/{company_id}/delivery_address',
            path: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Company Invoice Address
     * Edit company invoice address
     * @param companyId
     * @param requestBody
     * @returns AddressOut Successful Response
     * @throws ApiError
     */
    public updateCompanyInvoiceAddressUsersCompaniesCompanyIdInvoiceAddressPatch(
        companyId: string,
        requestBody: AddressEdit,
    ): CancelablePromise<AddressOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/companies/{company_id}/invoice_address',
            path: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Company Invoice Address
     * @param companyId
     * @param requestBody
     * @returns AddressOut Successful Response
     * @throws ApiError
     */
    public createCompanyInvoiceAddressUsersCompaniesCompanyIdInvoiceAddressPost(
        companyId: string,
        requestBody: AddressIn,
    ): CancelablePromise<AddressOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/companies/{company_id}/invoice_address',
            path: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Company Detail
     * Edit company partial on company detail
     * @param companyId
     * @param requestBody
     * @returns CompanyOut Successful Response
     * @throws ApiError
     */
    public updateCompanyDetailUsersCompaniesCompanyIdCompanyDetailPatch(
        companyId: string,
        requestBody: CompanyDetailEdit,
    ): CancelablePromise<CompanyOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/companies/{company_id}/company_detail',
            path: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Company Contact Detail
     * Edit company partial on contact detail
     * @param companyId
     * @param requestBody
     * @returns CompanyOut Successful Response
     * @throws ApiError
     */
    public updateCompanyContactDetailUsersCompaniesCompanyIdContactDetailPatch(
        companyId: string,
        requestBody: CompanyContactEdit,
    ): CancelablePromise<CompanyOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/companies/{company_id}/contact_detail',
            path: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Subcompany Customers By Company
     * Get subcompany customers by company id (Bestelaccounts)
     * @param companyId
     * @param page Page number
     * @param size Page size
     * @returns Page_BestelAccountOut_ Successful Response
     * @throws ApiError
     */
    public getSubcompanyCustomersByCompanyUsersSubcompanyCustomersCompanyIdGet(
        companyId: string,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_BestelAccountOut_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/subcompany_customers/{company_id}',
            path: {
                'company_id': companyId,
            },
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Subsidiaries
     * Get all subsidiaries
     * @param search
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_LocationOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getAllSubsidiariesUsersSubsidiariesPost(
        search?: (string | null),
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_all_subsidiaries_users_subsidiaries_post,
    ): CancelablePromise<Page_LocationOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/subsidiaries',
            query: {
                'search': search,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Subsidiary By Company Id
     * Get all subsidiaries
     * @param companyId
     * @returns SubsidiaryOverviewOut Successful Response
     * @throws ApiError
     */
    public getSubsidiaryByCompanyIdUsersSubsidiariesCompanyIdGet(
        companyId: string,
    ): CancelablePromise<SubsidiaryOverviewOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/subsidiaries/{company_id}',
            path: {
                'company_id': companyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Subsidiary
     * Create a new subsidiary
     * @param requestBody
     * @returns SubsidiaryOut Successful Response
     * @throws ApiError
     */
    public createSubsidiaryUsersCreateSubsidiaryPost(
        requestBody: Body_create_subsidiary_users_create_subsidiary_post,
    ): CancelablePromise<SubsidiaryOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/create_subsidiary',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Subsidiary
     * Update an existing subsidiary
     * @param companyId
     * @param requestBody
     * @returns SubsidiaryOut Successful Response
     * @throws ApiError
     */
    public updateSubsidiaryUsersSubsidiaryCompanyIdPatch(
        companyId: string,
        requestBody: Body_update_subsidiary_users_subsidiary__company_id__patch,
    ): CancelablePromise<SubsidiaryOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/subsidiary/{company_id}',
            path: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Employees
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_MedewerkerOverzichtOut_ Successful Response
     * @throws ApiError
     */
    public getAllEmployeesUsersEmployeesPost(
        search?: (string | null),
        sortingField?: 'Naam' | 'Functie' | 'Emailadres' | 'Telefoonnummer' | 'Status',
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_all_employees_users_employees_post,
    ): CancelablePromise<Page_MedewerkerOverzichtOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/employees',
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Employees
     * @returns MedewerkerNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllEmployeesUsersEmployeeNamesPost(): CancelablePromise<MedewerkerNaamOutList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/employee_names',
        });
    }

    /**
     * Get Employee By Id
     * Get an employee by ID
     * @param employeeId
     * @returns EmployeeOut Successful Response
     * @throws ApiError
     */
    public getEmployeeByIdUsersEmployeesEmployeeIdGet(
        employeeId: string,
    ): CancelablePromise<EmployeeOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/employees/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Employee
     * Edit an employee
     * @param employeeId
     * @param requestBody
     * @returns EmployeeOut Successful Response
     * @throws ApiError
     */
    public updateEmployeeUsersEmployeesEmployeeIdPatch(
        employeeId: string,
        requestBody: MedewerkerDetailEdit,
    ): CancelablePromise<EmployeeOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/employees/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Employee
     * Delete an employee
     * @param employeeId
     * @returns void
     * @throws ApiError
     */
    public deleteEmployeeUsersEmployeesEmployeeIdDelete(
        employeeId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/employees/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Employee Address
     * Create a new address for an employee
     * @param employeeId
     * @param requestBody
     * @returns AddressOut Successful Response
     * @throws ApiError
     */
    public createEmployeeAddressUsersEmployeesEmployeeIdAddressPost(
        employeeId: string,
        requestBody: AddressIn,
    ): CancelablePromise<AddressOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/employees/{employee_id}/address',
            path: {
                'employee_id': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Employee Address
     * @param employeeId
     * @param requestBody
     * @returns AddressOut Successful Response
     * @throws ApiError
     */
    public updateEmployeeAddressUsersEmployeesEmployeeIdAddressPatch(
        employeeId: string,
        requestBody: AddressEdit,
    ): CancelablePromise<AddressOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/employees/{employee_id}/address',
            path: {
                'employee_id': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Employee Address
     * Remove a connected address from an employee
     * @param employeeId
     * @returns void
     * @throws ApiError
     */
    public removeEmployeeAddressUsersEmployeesEmployeeIdAddressDelete(
        employeeId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/employees/{employee_id}/address',
            path: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Customers
     * Get all customers filtered
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_KlantenOverzichtOut_ Successful Response
     * @throws ApiError
     */
    public getAllCustomersUsersCustomersPost(
        search?: (string | null),
        sortingField?: 'Naam' | 'Emailadres' | 'Status',
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_all_customers_users_customers_post,
    ): CancelablePromise<Page_KlantenOverzichtOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/customers',
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer By Id
     * @param customerId
     * @returns CustomerOut Successful Response
     * @throws ApiError
     */
    public getCustomerByIdUsersCustomersCustomerIdGet(
        customerId: string,
    ): CancelablePromise<CustomerOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/customers/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Customer
     * @param customerId
     * @param requestBody
     * @returns CustomerOut Successful Response
     * @throws ApiError
     */
    public updateCustomerUsersCustomersCustomerIdPatch(
        customerId: string,
        requestBody: KlantDetailEdit,
    ): CancelablePromise<CustomerOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/customers/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Customer By Id
     * Soft Delete customer by id
     * @param customerId
     * @returns void
     * @throws ApiError
     */
    public deleteCustomerByIdUsersCustomersCustomerIdDelete(
        customerId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/customers/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Customer Invoice Address
     * @param customerId
     * @param requestBody
     * @returns AddressOut Successful Response
     * @throws ApiError
     */
    public createCustomerInvoiceAddressUsersCustomersCustomerIdInvoiceAddressPost(
        customerId: string,
        requestBody: AddressIn,
    ): CancelablePromise<AddressOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/customers/{customer_id}/invoice_address',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Customer Invoice Address
     * @param customerId
     * @param requestBody
     * @returns AddressOut Successful Response
     * @throws ApiError
     */
    public editCustomerInvoiceAddressUsersCustomersCustomerIdInvoiceAddressPatch(
        customerId: string,
        requestBody: AddressEdit,
    ): CancelablePromise<AddressOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/customers/{customer_id}/invoice_address',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Customer Delivery Address
     * @param customerId
     * @param requestBody
     * @returns AddressOut Successful Response
     * @throws ApiError
     */
    public createCustomerDeliveryAddressUsersCustomersCustomerIdDeliveryAddressPost(
        customerId: string,
        requestBody: AddressIn,
    ): CancelablePromise<AddressOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/customers/{customer_id}/delivery_address',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Customer Delivery Address
     * @param customerId
     * @param requestBody
     * @returns AddressOut Successful Response
     * @throws ApiError
     */
    public editCustomerDeliveryAddressUsersCustomersCustomerIdDeliveryAddressPatch(
        customerId: string,
        requestBody: AddressEdit,
    ): CancelablePromise<AddressOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/customers/{customer_id}/delivery_address',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Customer Addresses
     * Remove a connected address from a customer
     * @param customerId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public removeCustomerAddressesUsersCustomersCustomerIdAddressesDelete(
        customerId: string,
        requestBody: CustomerAddress,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/customers/{customer_id}/addresses',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer Order Metrics By Id
     * Get customer order metrics by ID
     * @param customerId
     * @returns CustomerMetricOut Successful Response
     * @throws ApiError
     */
    public getCustomerOrderMetricsByIdUsersCustomersCustomerIdOrderMetricsGet(
        customerId: string,
    ): CancelablePromise<CustomerMetricOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/customers/{customer_id}/order_metrics',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer Orders By Id
     * Get customer orders by ID
     * @param customerId
     * @param page Page number
     * @param size Page size
     * @returns Page_CustomerDetailOrderOut_ Successful Response
     * @throws ApiError
     */
    public getCustomerOrdersByIdUsersCustomersCustomerIdOrdersGet(
        customerId: string,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_CustomerDetailOrderOut_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/customers/{customer_id}/orders',
            path: {
                'customer_id': customerId,
            },
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Customer By Id
     * Restore soft deleted customer by id
     * @param customerId
     * @returns CustomerOut Successful Response
     * @throws ApiError
     */
    public restoreCustomerByIdUsersCustomersCustomerIdRestoreGet(
        customerId: string,
    ): CancelablePromise<CustomerOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/customers/{customer_id}/restore',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Address By Id
     * @param addressId
     * @returns AddressOut Successful Response
     * @throws ApiError
     */
    public getAddressByIdUsersAddressesAddressIdGet(
        addressId: string,
    ): CancelablePromise<AddressOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/addresses/{address_id}',
            path: {
                'address_id': addressId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Location By Id
     * Get location by ID
     * @param locationId
     * @returns LocationOut Successful Response
     * @throws ApiError
     */
    public getLocationByIdUsersLocationsLocationIdGet(
        locationId: string,
    ): CancelablePromise<LocationOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/locations/{location_id}',
            path: {
                'location_id': locationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Location By Id
     * Delete location by id
     * @param locationId
     * @param hard
     * @returns void
     * @throws ApiError
     */
    public deleteLocationByIdUsersLocationsLocationIdDelete(
        locationId: string,
        hard: boolean = false,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/locations/{location_id}',
            path: {
                'location_id': locationId,
            },
            query: {
                'hard': hard,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Locations
     * Get all locations
     * @returns LocationOutList Successful Response
     * @throws ApiError
     */
    public getAllLocationsUsersLocationsGet(): CancelablePromise<LocationOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/locations',
        });
    }

    /**
     * Create Location
     * @param requestBody
     * @returns LocationOut Successful Response
     * @throws ApiError
     */
    public createLocationUsersLocationsPost(
        requestBody: LocationIn,
    ): CancelablePromise<LocationOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/locations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Debiteuren
     * Get all names of locations that have a customer relation (debiteur)
     * @returns LocationNaamOutList Successful Response
     * @throws ApiError
     */
    public getDebiteurenUsersDebiteurenGet(): CancelablePromise<LocationNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/debiteuren',
        });
    }

    /**
     * Restore Location By Id
     * Restore location by id
     * @param locationId
     * @returns LocationOut Successful Response
     * @throws ApiError
     */
    public restoreLocationByIdUsersLocationsLocationIdRestoreGet(
        locationId: string,
    ): CancelablePromise<LocationOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/locations/{location_id}/restore',
            path: {
                'location_id': locationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Location Categories
     * Get all location categories
     * @returns LocationCategoryOutList Successful Response
     * @throws ApiError
     */
    public getAllLocationCategoriesUsersLocationCategoriesGet(): CancelablePromise<LocationCategoryOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/location_categories',
        });
    }

    /**
     * Get Location Weekdays By Location Id
     * Get location weekdays
     * @param locationId
     * @returns LocationWeekDayOutList Successful Response
     * @throws ApiError
     */
    public getLocationWeekdaysByLocationIdUsersLocationWeekdaysLocationIdGet(
        locationId: string,
    ): CancelablePromise<LocationWeekDayOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/location_weekdays/{location_id}',
            path: {
                'location_id': locationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Location Special Days By Location Id
     * Get location special days
     * @param locationId
     * @returns LocationSpecialDayOutList Successful Response
     * @throws ApiError
     */
    public getLocationSpecialDaysByLocationIdUsersLocationSpecialdaysLocationIdGet(
        locationId: string,
    ): CancelablePromise<LocationSpecialDayOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/location_specialdays/{location_id}',
            path: {
                'location_id': locationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Logo To S3
     * Upload a file to a (private or public) S3 bucket, this endpoint also adds the url to the TenantStyle
     * @param formData
     * @param _public
     * @returns any Successful Response
     * @throws ApiError
     */
    public uploadLogoToS3UsersUploadLogoImagePost(
        formData: Body_upload_logo_to_s3_users_upload_logo_image_post,
        _public: boolean = true,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/upload_logo_image',
            query: {
                'public': _public,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Tenant Style
     * Get tenant style
     * @returns TenantStyleOut Successful Response
     * @throws ApiError
     */
    public getTenantStyleUsersTenantStyleGet(): CancelablePromise<TenantStyleOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/tenant_style',
        });
    }

    /**
     * Update Tenant Style
     * Update tenant style
     * @param requestBody
     * @returns TenantStyleOut Successful Response
     * @throws ApiError
     */
    public updateTenantStyleUsersTenantStylePatch(
        requestBody: TenantStyleEdit,
    ): CancelablePromise<TenantStyleOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/tenant_style',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
