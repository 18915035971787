import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Divider, Fade, IconButton, Modal, Step, StepLabel, Stepper, Typography } from "@mui/material";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { batch, Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ButtonFull from "../../buttons/ButtonFull";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ButtonText from "../../buttons/ButtonText";
import { FormEvent, ReactElement } from "react";

interface stepsProps {
    label: string
    content: ReactElement<any, any>
}

interface VerticalStepperProps {
    open: Signal<boolean>
    title: string
    steps: stepsProps[]
    finalContent: ReactElement<any, any>
    func: (p: Signal<number>) => void
    resetFunc: () => void
}

const VerticalStepper: React.FC<VerticalStepperProps> = ({open, title, steps, finalContent, func, resetFunc}) => {
    useSignals();

    const activeStep: Signal<number> = useSignal(0);
    const completedStep: Signal<number> = useSignal(0);

    const expanded: Signal<boolean> = useSignal(false);

    const nextStep = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        activeStep.value += 1;

        if (activeStep.value > completedStep.value) {
            completedStep.value = activeStep.value;
        }
        if (completedStep.value >= steps.length) {
            completedStep.value += 1;
        }
    }

    const reset = () => {
        setTimeout(() => {
            batch(() => {
                activeStep.value = 0;
                completedStep.value = 0;
                expanded.value = false;
            })
            resetFunc();
          }, 1000);
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '1000px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        outline: 0,
        maxHeight: '90vh',
        overflowY: 'auto'
      };

    return (
        <Modal
            aria-labelledby="connect-mollie-extra-modal"
            aria-describedby="Modal to connect mollie extra info"
            open={open.value}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={open.value}>
                <Box sx={style}>
                    {/* Header */}
                    <Box sx={{display: 'flex', justifyContent: 'space-between', mb: '32px'}}>
                        {/* Title */}
                        <Typography variant='headlineLarge' sx={{mt: '64px', ml: '64px'}}>
                            {title}
                        </Typography>
                        <IconButton sx={{width: 'fit-content',height: 'fit-content', ml: 'auto', mt: '8px', mr: '8px'}} onClick={() => {open.value = false; reset()}}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Box>
                    {/* Body */}
                    <Box sx={{display: 'flex', gap: '16px'}}>
                        {/* Stepper */}
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', width: 'fit-content', px: '64px', pb: '64px'}}>
                            <Stepper activeStep={activeStep.value} orientation="vertical" sx={{'.MuiStepConnector-lineVertical': {ml: '8px'}, '.MuiStepContent-root': {ml: '20px'}}}>
                                {steps.map((step, index) => (
                                    <Step key={step.label}>
                                    <StepLabel 
                                        sx={{display: 'flex', alignContent: 'center'}}
                                        icon={
                                            <Box sx={{display: 'flex', width: '40px', height: '40px', borderRadius: '32px', backgroundColor: 'primary.main', color: 'white', alignItems: 'center', justifyContent: 'center'}}>
                                                {
                                                    completedStep.value > index ?
                                                    <CheckOutlinedIcon />
                                                    :
                                                    index + 1
                                                }
                                            </Box>
                                        }
                                        >
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                                            <Typography variant='labelMedium'>
                                                Stap {index + 1} van {steps.length}
                                            </Typography>
                                            <Typography variant='titleMedium'>
                                                    {step.label}
                                            </Typography>
                                        </Box>
                                    </StepLabel>
                                </Step>
                                ))}
                            </Stepper>        
                        </Box>
                        {/* Content */}
                        <Divider orientation="vertical" flexItem variant='middle' sx={{mb: '64px'}}/>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', flexGrow: '1', alignItems: 'stretch'}}>             
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', px: '64px', pb: '64px', height: '100%'}}>
                            <Typography variant='titleLarge'>
                                { 
                                    typeof steps[activeStep.value] === 'undefined' ?
                                    <>Voltooid</>
                                    :
                                    <>{activeStep.value + 1}. {steps[activeStep.value].label}</>
                                }
                            </Typography>
                            {/* Content */}
                            <form id='form' onSubmit={nextStep} style={{height: '100%'}}>
                                { 
                                    typeof steps[activeStep.value] === 'undefined' ?
                                    finalContent
                                    :
                                    steps[activeStep.value].content
                                }
                            </form>
                                

                            {/* Buttons */}
                            {
                                activeStep.value === 0 ?
                                <Box sx={{display: 'flex', ml: 'auto', gap: '8px', mt: 'auto'}}>
                                    <ButtonFull type='submit' text='Volgende' onClick={() => {}}/>
                                </Box>
                                : 
                                activeStep.value === steps.length - 1 ?
                                <Box sx={{display: 'flex', justifyContent: 'center', gap: '8px', flexDirection: 'column', alignItems: 'center', mt: 'auto'}}>
                                    <ButtonFull type='submit' text='Voltooien' onClick={() => {func(activeStep);}} width='100%'/>
                                    <ButtonText text='Vorige' onClick={() => {activeStep.value -= 1}} margin='none'/>
                                </Box>
                                :
                                activeStep.value >= steps.length ?
                                <>
                                </>
                                :
                                <Box sx={{display: 'flex', ml: 'auto', gap: '8px', mt: 'auto', alignItems: 'center'}}>
                                    <ButtonText text='Vorige' onClick={() => {activeStep.value -= 1}}/>
                                    <ButtonFull type='submit' text='Volgende' onClick={() => {}}/>
                                </Box>
                            }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
  };

export default VerticalStepper;