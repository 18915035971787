import { Alert, AlertColor, Backdrop, Box, Button, CircularProgress, FormHelperText, Grow, IconButton, MenuItem, Paper, Select, Snackbar, TextField, ThemeProvider, Typography } from "@mui/material";
import { useMediaQuery } from 'react-responsive'
import theme from "../../Theme";
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import { useNavigate, useParams } from "react-router-dom";
import NotMobile from "../../components/NotMobile";
import { FormEvent, useEffect, useState } from "react";
import { apiClient } from "../../functions/Login";
import { AllergenOut, DriveThruEventDayOut, DriveThruEventLocationOut, DriveThruLocalOrderIn, DriveThruOrderProductIn, ShopOut } from "../../assets";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Alerter from "../../components/Alerter";
import ThempTheme from "../../TempTheme";

export default function DriveThruFastOrder() {
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [processing, setProcessing] = useState(false);
    const isMobileorTablet = useMediaQuery({ query: '(max-width: 1224px)' })
    const shop_id = String(useParams().shop_id)
    const event_location_id = String(useParams().location_id);
    const deskParam = String(useParams().desk);
    const [fullCart, setFullCart] = useState(false);

    const openSnackbar = () => {
        setSnackbarOpen(true);
    };
    const closeSnackbar = () => {
        
        setSnackbarOpen(false);
    };

    // Alerter constants
    const [openAlerter, setOpenAlerter] = useState(false);
    const [severity, setSeverity] = useState<AlertColor>('error');
    const [text, setText] = useState('')
    
    // form constants
    const [email, setEmail] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [vehicle, setVehicle] = useState('');

     // cart constants
     type cartItem = {
        id: string;
        productName: string;
        productOption: string;
        productDescription: string;
        price_incl: number;
        price_excl: number;
        image_url: string;
        allergens: AllergenOut[];
        amount: number;
        inCart: boolean;
    }
    const [cart, setCart] = useState<cartItem[]>([]);
    const cartTotal = () => {
        var total = 0.00;
        cart.filter(item => item.amount !== 0).forEach((item) => {
            total = total + (item.amount * item.price_incl)
        });
        return total.toFixed(2);
    }   
    const handleSetAmount = (i: number, amount: number, inCart: boolean = true) => {
        if (!Number.isNaN(amount) && amount >= 0) {
            const newCart = cart.map((item, index) => {
                if (index === i) {
                    return {...item, amount: amount, inCart: inCart};
                }
                return item;
            })
            setCart(newCart);
        }
        
    }
    
    const submitOrder = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setProcessing(true);
        const newOrder = {
            order_client_email: email,
            event_location_id: event_location_id,
            shop_id: shop_id,
            vehicle: vehicle,
            order_client_business: company
        } as DriveThruLocalOrderIn
        apiClient().driveThru.createOrderLocalDriveThruCreateOrderLocalPost(newOrder).then((r) => {
            const items = cart.filter(item => item.amount > 0);
            const order_id = r.id;
            items.forEach((item, i) => {
                const newOrderProduct = {
                    amount: item.amount,
                    price_excl_vat: item.price_excl,
                    vat_price: item.price_incl - item.price_excl,
                    variant_active_id: item.id,
                    drive_thru_order_id: r.id
                } as DriveThruOrderProductIn
                if (i === items.length - 1) {
                    apiClient().driveThru.addProductToOrderDriveThruAddProductToOrderOrderIdPost(r.id, newOrderProduct).then(() => {
                        apiClient().driveThru.createPaymentByOrderIdDriveThruPaymentsPublicOrderIdGet(r.id).then((r) => {
                            setProcessing(false);
                            navigate(`/drive-thru/betalen/${shop_id}/${event_location_id}/${order_id}`, {state: {url: r.qr_code_link}})
                        }).catch(() => {
                            setProcessing(false);
                            setSeverity('error');
                            setText('Er lijkt iets fout te gaan, controleer de gegevens en probeer het opnieuw.');
                            setOpenAlerter(true);
                        })
                    });
                } else {
                    apiClient().driveThru.addProductToOrderDriveThruAddProductToOrderOrderIdPost(r.id, newOrderProduct);
                }
            })
        }).catch(() => {
            setProcessing(false);
            setSeverity('error');
            setText('Er lijkt iets fout te gaan, controleer de gegevens en probeer het opnieuw.');
            setOpenAlerter(true);
        })
    }

    const [location, setLocation] = useState<DriveThruEventLocationOut>({} as DriveThruEventLocationOut);
    const [locationsNum, setLocationsNum] = useState(0);
    const [shopInfo, setShopInfo] = useState<ShopOut | null>(null);
    
    useEffect(() => {
        apiClient().driveThru.getEventLocationByIdDriveThruEventLocationsEventLocationIdGet(event_location_id).then((r) => {
            setLocation(r);
        }).catch((error) => {
            console.log(error)
        })

        apiClient().shops.getShopByIdShopsShopsShopIdGet(shop_id).then((r) => {
            setLocationsNum(r.drive_thru_event_locations!.length);
            apiClient().driveThru.getShopInfoByWebshopUrlDriveThruShopInfoLocationIdPost( '', {webshop_url: r.webshop_url}).then((r) => {
                if (r === null) {
                } else {
                    setShopInfo(r);
                    var newCartItems: cartItem[] = [];
                    r.variant_actives!.forEach((va) => {
                        va.variant.product_options!.forEach((v) => {
                            var newCartItem = {
                                id: va.id,
                                productName: va.variant.sales_product?.sales_product_name,
                                productOption: v.product_option_name,
                                productDescription: va.variant.sales_product!.sales_product_description,
                                price_incl: va.full_promotional_price_incl_vat_rounded,
                                price_excl: va.full_promotional_price_excl_vat,
                                image_url: va.variant.variant_image![0],
                                allergens: va.variant.sales_product!.allergens!,
                                amount: 0,
                                inCart: false
                            } as cartItem
                            newCartItems.push(newCartItem);
                        })
                    })
                    setCart(newCartItems);
                }
            })
        })
        if(deskParam !== "undefined") {
            openSnackbar();
        }
        
    }, [])

    return (
        <ThemeProvider theme={ThempTheme}>
             <Alerter
            open={openAlerter}
            setOpen={setOpenAlerter}
            severity={severity}
            text={text}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: 20 }}
                open={processing}
            >
                <CircularProgress color="primary" />
            </Backdrop>
            {isMobileorTablet ?  
            <Box sx={{minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: 'secondary.main'}}>
                {/* Header */}
                <Box sx={{height: '30px', backgroundColor: '#705842', display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: '16px', py: '16px'}}>
                </Box>
                {
                    fullCart && cartTotal() !== "0.00" ? 
                    <Box sx={{height: '100%', backgroundColor: 'secondary.main', display: 'flex', flexDirection: 'column', justifyContent: 'start'}}>
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', my: '32px'}}>
                        <IconButton onClick={() => {setFullCart(false)}} sx={{color: '#000'}}>
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                        <Typography variant='headlineLarge' sx={{ml: '4px'}}>
                            Gegevens invoeren
                        </Typography>
                    </Box>
                        <Box sx={{display: 'flex', width: '100%', gap: '16px', flexDirection: 'column'}}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mx: '32px', alignItems: 'center'}}>
                                    <Typography variant='labelLarge'>
                                        Totaalbedrag
                                    </Typography>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <EuroSymbolOutlinedIcon />
                                        <Typography variant="headlineSmall" sx={{pl: '4px'}}>
                                            {cartTotal()}
                                        </Typography> 
                                    </Box>
                            </Box> 
                            <form onSubmit={(e) => {submitOrder(e)}} autoComplete="chrome-off" style={{width: '90%', marginLeft: 'auto', marginRight: 'auto'}}>
                                <TextField autoComplete="chrome-off" type='email' onChange={(e) => {setEmail(e.target.value)}} required label="Emailadres" variant="outlined" sx={{width: '100%', backgroundColor: '#fff'}}/>
                                <Select
                                required
                                displayEmpty
                                value={vehicle}
                                onChange={(e) => {setVehicle(e.target.value)}}
                                sx={{width: '100%', mt: '16px', backgroundColor: '#fff'}}
                                >
                                    <MenuItem disabled value='' key=''>
                                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                            <DirectionsCarFilledOutlinedIcon />
                                            <Typography variant="labelLarge" sx={{my: 'auto', ml: '16px'}}>
                                                Voertuig   
                                            </Typography> 
                                        </Box>
                                    </MenuItem>
                                    {
                                        shopInfo?.drive_thru_event_locations![0].drive_thru_event_vehicles!.map((vehicle) => {
                                            return <MenuItem value={vehicle.drive_thru_event_vehicle} key={vehicle.drive_thru_event_vehicle}>
                                                <Typography variant="labelLarge">
                                                    {vehicle.drive_thru_event_vehicle}
                                                </Typography>
                                            </MenuItem>
                                        })
                                    }
                                </Select>
                                <TextField type="text" autoComplete="chrome-off" onChange={(e) => {setCompany(e.target.value)}} label="Bedrijfsnaam" variant="outlined" sx={{width: '100%', my: '16px', backgroundColor: '#fff' }} inputProps={{autoComplete: 'false'}}
                                />
                                <Button type="submit" variant="contained" color="primary" sx={{borderRadius: '20px', width: '100%', height: '40px', mx: 'auto', mt: '16px'}}>
                                <Typography variant="labelLarge" sx={{px: '12px'}}>
                                    Afrekenen
                                </Typography> 
                            </Button>
                            </form>
                            
                        </Box>
                    </Box>
                    :
                    <Box sx={{height: '100%', backgroundColor: 'secondary.main', display: 'flex', flexDirection: 'column'}}>
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', my: '32px'}}>
                        <IconButton onClick={() => {navigate(`/drive-thru/opties/${shop_id}/${event_location_id}`)}} sx={{color: '#000'}}>
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                        <Typography variant='headlineLarge' sx={{ml: '4px'}}>
                            Plaats bestelling
                        </Typography>
                        </Box>
                        <Box sx={{display: 'flex', width: '100%', gap: '16px', flexDirection: 'column'}}>
                        {
                                cart.map((item, i) => 
                                    <Paper key={item.id} variant='outlined' sx={{display: 'flex', my: '8px', flexDirection: 'column', width: '80%', mx: 'auto'}}>
                                        {/* picture */}
                                        <Box sx={{height: '200px', minWidth: '200px', mr: '16px', width: '100%', display: 'flex', alignItems: 'center'}}>
                                            <img src={item.image_url} alt="Product foto" style={{
                                                width: 'auto', height: 'auto', maxWidth: '200px', maxHeight: '200px', marginLeft: 'auto', marginRight: 'auto'
                                            }}/>
                                        </Box>
                                        {/* description */}
                                        <Box sx={{p: '16px', display: 'flex', flexDirection: 'column', width: 'auto'}}>
                                            <Typography variant="titleLarge">
                                                {item.productName} ({item.productOption})
                                            </Typography> 
                                            <Typography sx={{mb: '24px', mt: '8px'}} variant="bodyLarge">
                                                {item.productDescription}
                                            </Typography> 
                                            <Box sx={{display: 'flex', gap: '8px', mb: '16px', mt: '-8px'}}>
                                            {
                                                item.allergens.map(a => {
                                                    return <img key={a.id} style={{width: '48px', height: '48px'}} src={a.allergen_icon ? a.allergen_icon : undefined} alt={a.allergen_name} />
                                                })
                                            }
                                            </Box>
                                            
                                            <Box sx={{display: 'flex', mt: 'auto', height: '70px', flexDirection: 'column', width: '100%'}}>
                                                <Box sx={{display: 'flex', alignItems: 'center', mb: '8px'}}>
                                                    <EuroSymbolOutlinedIcon />
                                                    <Typography variant="headlineSmall" sx={{pl: '4px'}}>
                                                        {item.price_incl!.toFixed(2)}
                                                    </Typography> 
                                                </Box>
                                                {
                                                    !item.inCart ?
                                                        <IconButton onClick={() => {handleSetAmount(i, (item.amount + 1))}} sx={{width: '32px', height: '32px', ml: 'auto', backgroundColor: 'primary.main', color: '#fff', "&:hover": { backgroundColor:'primary.main'}}}>
                                                            <AddOutlinedIcon />
                                                        </IconButton>
                                                    :
                                                        <Grow 
                                                            in={item.inCart}
                                                            style={{ transformOrigin: '0 0 0' }}
                                                            {...(item.inCart ? { timeout: 400 } : {})}
                                                        >
                                                            <Box sx={{ml: 'auto', display: 'flex', alignItems: 'center'}}>
                                                                {
                                                                    item.amount <= 1 ?
                                                                    <IconButton onClick={() => {handleSetAmount(i, 0, false)}} sx={{width: '32px', height: '32px', my: 'auto', backgroundColor: 'primary.main', color: '#fff', "&:hover": { backgroundColor:'primary.main'}}}>
                                                                        <DeleteOutlinedIcon />
                                                                    </IconButton>
                                                                    : 
                                                                    <IconButton onClick={() => {handleSetAmount(i, (item.amount - 1))}} sx={{width: '32px', height: '32px', my: 'auto', backgroundColor: 'primary.main', color: '#fff', "&:hover": { backgroundColor:'primary.main'}}}>
                                                                        <RemoveOutlinedIcon />
                                                                    </IconButton>

                                                                }
                                                                <TextField
                                                                    id={item.id}
                                                                    variant='outlined'
                                                                    type="tel"
                                                                    size="small"
                                                                    value={item.amount === 0 ? '' : item.amount}
                                                                    onChange={(e) => {handleSetAmount(i, +e.target.value)}}
                                                                    onBlur={(e) => {if (+e.target.value === 0) {handleSetAmount(i, 0, false)}}}
                                                                    InputProps={{ inputProps: { style: {textAlign: 'center'}, pattern: "[0-9]*"  } }}
                                                                    sx={{width: '60px', mx: '12px', '& fieldset': {
                                                                        borderRadius: '8px',
                                                                      },}}
                                                                /> 
                                                                <IconButton onClick={() => {handleSetAmount(i, (item.amount + 1))}} sx={{width: '32px', height: '32px', my: 'auto', backgroundColor: 'primary.main', color: '#fff', "&:hover": { backgroundColor:'primary.main'}}}>
                                                                    <AddOutlinedIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </Grow>
                                                }
                                                
                                            </Box>
                                        </Box>
                                    </Paper>
                                )
                            }
                        <Box sx={{display: 'flex', justifyContent: 'space-between', mx: '32px', alignItems: 'center', mt: 'auto'}}>
                            <Typography variant='labelLarge'>
                                Totaalbedrag
                            </Typography>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <EuroSymbolOutlinedIcon />
                                <Typography variant="headlineSmall" sx={{pl: '4px'}}>
                                    {cartTotal()}
                                </Typography> 
                            </Box>
                        </Box>
                        <Button disabled={cartTotal() === "0.00"} onClick={() => {setFullCart(true);  window.scrollTo(0, 0);}} variant="contained" color="primary" sx={{borderRadius: '20px', width: '90%', height: '40px', mx: 'auto'}}>
                                <Typography variant="labelLarge" sx={{px: '12px'}}>
                                    Gegevens invoeren en afrekenen
                                </Typography> 
                            </Button>
                            <FormHelperText error={true} sx={{mx: 'auto', mb: '16px', visibility: cartTotal() === '0.00' ? 'visible' : 'hidden'}}>
                                <Typography variant="labelSmall">
                                    Voeg eerst producten toe.
                                </Typography>
                                
                            </FormHelperText>  
                        </Box>
                    </Box>
                }
                
            </Box>
            : 
            // Not mobile or tablet device
            <NotMobile />
            }
        </ThemeProvider>
    );
}


