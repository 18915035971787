import theme from '../../Theme'
import { ThemeProvider } from '@emotion/react';
import { Box, Checkbox, Collapse, Divider, FormControl, FormControlLabel, FormGroup, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Switch, TextField, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonFull from '../../components/buttons/ButtonFull';
import ButtonOutlined from '../../components/buttons/ButtonOutlined';
import Split from '../../components/layout/Split';
import ButtonBack from '../../components/buttons/ButtonBack';
import Card from '../../components/layout/Card';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { Signal } from '@preact/signals-react';
import { useEffect } from 'react';
import { apiClient } from '../../functions/Login';
import Confirm from '../../components/modals/Confirm';
import OpeningTimes from '../../components/OpeningTimes';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { AddressEdit, Body_update_subsidiary_users_subsidiary__company_id__patch, CompanyEdit, LocationNaamOut, LocationSpecialDayIn, LocationWeekDayEdit, SubsidiaryEdit, SubsidiaryLocationEdit } from '../../assets';

interface openingTime {
    id: string
    name: string
    date: Date
    openFrom: Dayjs
    openTill: Dayjs
    active: boolean
}

export default function CompanyDetails() {
    dayjs.extend(customParseFormat)

    const navigate = useNavigate();
    const company_id = String(useParams().company_id);

    useSignals();
    // Define signals
    const openDeleteModal: Signal<boolean> = useSignal(false);
    const is_active: Signal<boolean> = useSignal(true);

    // category logic
    const categories: Signal<string[]> = useSignal([]);
    const selectedCategories: Signal<string[]> = useSignal([]);
    const handleCategoriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!selectedCategories.value.includes(event.target.name)) {
            selectedCategories.value = [...selectedCategories.value, event.target.name]
        } else {
            selectedCategories.value = selectedCategories.value.filter(val => val !== event.target.name)
        }
    };

    // location data logic
    const location_name: Signal<string> = useSignal('');
    const location_description: Signal<string> = useSignal('');
    const location_postal_code: Signal<string> = useSignal('');
    const location_number: Signal<string> = useSignal('');
    const location_number_suffix: Signal<string> = useSignal('');
    const location_street: Signal<string> = useSignal('');
    const location_city: Signal<string> = useSignal('');

    // contact data logic
    const email: Signal<string> = useSignal('');
    const location_phone_number: Signal<string> = useSignal('');
    const contact_person_first_name: Signal<string> = useSignal('');
    const contact_person_last_name: Signal<string> = useSignal('');
    const contact_person_preposition: Signal<string> = useSignal('');
    const contact_person_phone_number: Signal<string> = useSignal('');

    // billing logic
    const isMainDebit: Signal<boolean> = useSignal(false);
    const isNonDebit: Signal<boolean> = useSignal(false);
    const isSameAddress: Signal<boolean> = useSignal(false);
    const debits: Signal<LocationNaamOut[]> = useSignal([]);
    const selectedDebit: Signal<string> = useSignal('');
    const coc_number: Signal<string> = useSignal('');
    const debit_email: Signal<string> = useSignal('');
    const debit_postal_code: Signal<string> = useSignal('');
    const debit_number: Signal<string> = useSignal('');
    const debit_number_suffix: Signal<string> = useSignal('');
    const debit_street: Signal<string> = useSignal('');
    const debit_city: Signal<string> = useSignal('');

    // opening times logic
    const normal_opening_times: Signal<openingTime[]> = useSignal([]);
    const deviant_opening_times: Signal<openingTime[]> = useSignal([]);

    // order account logic
    const isOrdering: Signal<boolean> = useSignal(false);
    const ordering_email: Signal<string> = useSignal('');
    
    const saveSubsidiary = () => {
        console.log(contact_person_first_name.value)
        const normal: LocationWeekDayEdit[] = normal_opening_times.value.map((n) => ({
            id: n.id,
            weekday: n.name,
            location_closed: !n.active,
            starttime: n.openFrom.format('HH:mm'),
            endtime: n.openTill.format('HH:mm')
        } as LocationWeekDayEdit)) 

        const deviant: LocationSpecialDayIn[] = deviant_opening_times.value.map((n) => ({
            weekday: n.name,
            day: n.openFrom.date(),
            month: n.openFrom.month() + 1,
            location_closed: !n.active,
            starttime: n.openFrom.format('HH:mm'),
            endtime: n.openTill.format('HH:mm')
        } as LocationSpecialDayIn)) 
        const body = {
            edit_subsidiary: {
                location: {
                    name: location_name.value,
                    description: location_description.value,
                    is_active: is_active.value,
                    is_distribution: selectedCategories.value.includes('Distributie'),
                    is_delivery: selectedCategories.value.includes('Afleveradres (winkelfiliaal)'),
                    is_production: selectedCategories.value.includes('Productie'),
                    is_temporary: selectedCategories.value.includes('Tijdelijke verkooplocatie (bijv. Drive-Thru)')
                } as SubsidiaryLocationEdit,
                company: {
                    name: location_name.value,
                    email: email.value,
                    phone_number: location_phone_number.value,
                    contact_first_name: contact_person_first_name.value,
                    contact_preposition_name: contact_person_preposition.value,
                    contact_last_name: contact_person_last_name.value,
                    contact_full_name: contact_person_first_name.value + contact_person_preposition.value + contact_person_last_name.value,
                    contact_phone_number: contact_person_phone_number.value,
                    ...(isMainDebit && {
                        coc_number: coc_number.value,
                        invoice_email: debit_email.value,
                        parent_id: company_id
                    }),
                    ...((selectedDebit.value.length > 0 && !isMainDebit.value && !isNonDebit.value) && {
                        parent_id: selectedDebit.value
                    })
                } as CompanyEdit,
                delivery_address: {
                    street: location_street.value,
                    number: +location_number.value,
                    number_suffix: location_number_suffix.value,
                    postal_code: location_postal_code.value,
                    city: location_city.value
                } as AddressEdit,
                invoice_address: isSameAddress ? {
                    street: location_street.value,
                    number: +location_number.value,
                    number_suffix: location_number_suffix.value,
                    postal_code: location_postal_code.value,
                    city: location_city.value
                } as AddressEdit 
                : {
                    street: debit_street.value,
                    number: +debit_number.value,
                    number_suffix: debit_number_suffix.value,
                    postal_code: debit_postal_code.value,
                    city: debit_city.value
                } as AddressEdit
            } as SubsidiaryEdit,
            webshop_user_emails: [],
            location_week_days: normal,
            location_special_days: deviant,
        } as Body_update_subsidiary_users_subsidiary__company_id__patch
        apiClient().userManagement.updateSubsidiaryUsersSubsidiaryCompanyIdPatch(company_id, body).then((r) => {
            navigate('/bedrijf/locaties')
        })
       
    }

    useEffect(() => {
        apiClient().userManagement.getSubsidiaryByCompanyIdUsersSubsidiariesCompanyIdGet(company_id).then((r) => {
            is_active.value = r.location.is_active;
            location_name.value = r.location.name;
            location_description.value = r.location.description ? r.location.description : '';
            location_postal_code.value = r.location.company.delivery_address.postal_code;
            location_number.value = r.location.company.delivery_address.number.toString();
            location_number_suffix.value = r.location.company.delivery_address.number_suffix ? r.location.company.delivery_address.number_suffix : '';
            location_street.value = r.location.company.delivery_address.street;
            location_city.value = r.location.company.delivery_address.city;
            location_phone_number.value = r.location.company.phone_number ? r.location.company.phone_number : ''

            contact_person_first_name.value = r.location.company.contact_first_name ? r.location.company.contact_first_name : '';
            contact_person_last_name.value = r.location.company.contact_last_name ? r.location.company.contact_last_name : '';
            contact_person_preposition.value = r.location.company.contact_preposition_name ? r.location.company.contact_preposition_name : '';
            contact_person_phone_number.value = r.location.company.contact_phone_number ? r.location.company.contact_phone_number : '';

            email.value = r.location.company.email;
            if (r.location.company.parent_id === r.location.company.id) {
                isMainDebit.value = true;
                if (r.location.company.delivery_address.postal_code + r.location.company.delivery_address.number === r.location.company.invoice_address.postal_code + r.location.company.invoice_address.number) {
                    isSameAddress.value = true;
                }
                debit_postal_code.value =  r.location.company.invoice_address.postal_code;
                debit_number.value =  r.location.company.invoice_address.number.toString();
                debit_number_suffix.value =  r.location.company.invoice_address.number_suffix ? r.location.company.invoice_address.number_suffix : '';
                debit_street.value =  r.location.company.invoice_address.street;
                debit_city.value =  r.location.company.invoice_address.city;
                coc_number.value = r.location.company.coc_number ? r.location.company.coc_number : '';
                debit_email.value = r.location.company.invoice_email ? r.location.company.invoice_email : '';

            } else {
                selectedDebit.value = r.location.company.parent_id ? r.location.company.parent_id : ''
            }

            r.week_days!.map((day) => 
                normal_opening_times.value = [
                    ...normal_opening_times.value,
                    {
                        id: day.id,
                        name: day.weekday,
                        active: !day.location_closed,
                        date: new Date(),
                        openFrom: dayjs(day.starttime, 'HH:mm:ss'),
                        openTill: dayjs(day.endtime, 'HH:mm:ss')
                    } as openingTime
                ]
            )

            r.special_days!.map((day) => 
                deviant_opening_times.value = [
                    ...deviant_opening_times.value,
                    {
                        id: day.id,
                        name: day.day.toString().padStart(2, '0') + '-' + day.month.toString().padStart(2, '0'),
                        active: !day.location_closed,
                        date: new Date(),
                        openFrom: dayjs(day.starttime, 'HH:mm:ss'),
                        openTill: dayjs(day.endtime, 'HH:mm:ss')
                    } as openingTime
                ]
            )

            if (r.location.is_delivery) selectedCategories.value = [...selectedCategories.value, 'Afleveradres (winkelfiliaal)']
            if (r.location.is_distribution) selectedCategories.value = [...selectedCategories.value, 'Distributie']
            if (r.location.is_production) selectedCategories.value = [...selectedCategories.value, 'Productie']
            if (r.location.is_temporary) selectedCategories.value = [...selectedCategories.value, 'Tijdelijke verkooplocatie (bijv. Drive-Thru)']
        })
        apiClient().userManagement.getAllLocationCategoriesUsersLocationCategoriesGet().then((r) => {
            categories.value = r.items.map(item => item.name);
        })
        apiClient().userManagement.getDebiteurenUsersDebiteurenGet().then((r) => {
            debits.value = r.items
        })
    }, [])

    return (
      <ThemeProvider theme={theme}>
        <Confirm 
            open={openDeleteModal} 
            func={() => {apiClient().userManagement.deleteCompanyUsersCompaniesCompanyIdDelete(company_id).then(() => {navigate('/bedrijf/locaties')})}}
            information_text='Door op bevestigen te drukken wordt deze locatie permanent verwijdert.'
        />
        <Box sx={{mr: '16px', mb: '16px'}}>
            {/* Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '24px', gap: '8px'}}>
                <ButtonBack />

                <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                    {location_name.value}
                </Typography> 

                <ButtonOutlined Icon={DeleteOutlineOutlinedIcon} onClick={() => {openDeleteModal.value = true}} text='Locatie verwijderen'/>
                <ButtonFull type='submit' form='subsidiary' onClick={() => {}} text='Gegevens opslaan'/>
            </Box>

            {/* Body */}
            <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {e.preventDefault(); saveSubsidiary()}} id='subsidiary' style={{display: 'flex', gap: '16px', flexDirection: 'column'}}>
                <Split 
                    left={
                        <>
                            <Card 
                                title='Locatiegegevens' 
                                children={
                                    <>
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                            <Typography variant='titleMedium' sx={{fontWeight: '700'}}>
                                                Naam locatie
                                            </Typography>
                                            <TextField required label="Naam locatie" variant="outlined" value={location_name.value} onChange={(e) => {location_name.value = e.currentTarget.value}}/>
                                            <TextField label="Beschrijving" variant="outlined" value={location_description.value} onChange={(e) => {location_description.value = e.currentTarget.value}}/>
                                        </Box>
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                            <Typography variant='titleMedium' sx={{fontWeight: '700'}}>
                                                Adres locatie
                                            </Typography>
                                            <TextField required label="Postcode" variant="outlined" value={location_postal_code.value} onChange={(e) => {location_postal_code.value = e.currentTarget.value}}/>
                                            <Split 
                                                left={
                                                    <TextField required label="Huisnummer" variant="outlined" value={location_number.value} onChange={(e) => { if (e.currentTarget.value.match('^[0-9]*$')) location_number.value = e.currentTarget.value}}/> 
                                                }
                                                right={
                                                    <TextField label="Toevoeging" variant="outlined" value={location_number_suffix.value} onChange={(e) => {location_number_suffix.value = e.currentTarget.value}}/>
                                                }
                                            />
                                            <TextField required label="Straat" variant="outlined" value={location_street.value} onChange={(e) => {location_street.value = e.currentTarget.value}}/>
                                            <TextField required label="Plaats" variant="outlined" value={location_city.value} onChange={(e) => {location_city.value = e.currentTarget.value}}/>
                                        </Box>
                                    </>
                                }
                            />
                             <Card 
                                title='Soort locatie' 
                                children={
                                    <FormControl required error={selectedCategories.value.length < 1}>
                                        <FormGroup>
                                            {
                                                categories.value.map((category) =>
                                                    <FormControlLabel
                                                        key={selectedCategories.value.length + category}
                                                        control={
                                                        <Checkbox checked={selectedCategories.value.indexOf(category) > -1} onChange={handleCategoriesChange} name={category} />
                                                        }
                                                        label={category}
                                                    />
                                                )
                                            }
                                        </FormGroup>
                                    </FormControl>
                                }
                            />
                             {/* <Card 
                                title='Webshop' 
                                children={
                                    <>
                                    
                                    </>
                                }
                            /> */}
                             <Card 
                                title='Facturatie' 
                                children={
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                        <Typography variant='titleMedium' sx={{fontWeight: '700'}}>
                                            Debiteur
                                        </Typography>
                                        <Select
                                            required
                                            displayEmpty
                                            IconComponent={ExpandMoreOutlinedIcon}
                                            value={isMainDebit.value ? '' : selectedDebit.value}
                                            disabled={isMainDebit.value}
                                            onChange={(e) => {selectedDebit.value = e.target.value}}
                                            sx={{minWidth: '150px', borderRadius: '12px'}}
                                        >
                                            <MenuItem disabled value='' key=''>
                                                <Typography variant='titleMedium'>
                                                    Kies een hoofddebiteur
                                                </Typography>
                                            </MenuItem>
                                            {
                                                debits.value.map(debit =>
                                                    <MenuItem value={debit.id} key={debit.id} >
                                                        <Typography variant='titleMedium'>
                                                            {debit.name}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                        <Divider flexItem>OF</Divider>
                                        <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                            <Checkbox checked={isMainDebit.value} onChange={(e) => {isMainDebit.value = e.currentTarget.checked}}/>
                                            <Typography variant='bodyLarge'>
                                                Deze locatie is de hoofddebiteur
                                            </Typography>
                                        </Box>
                                        <Collapse in={isMainDebit.value} >
                                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                                <TextField required={isMainDebit.value} label="KVK-nummer" variant="outlined" value={coc_number.value} onChange={(e) => {coc_number.value = e.currentTarget.value}}/>
                                                <TextField required={isMainDebit.value} label="Facturatie mailadres" variant="outlined" value={debit_email.value} onChange={(e) => {debit_email.value = e.currentTarget.value}}/>
                                                <Typography variant='titleMedium' sx={{fontWeight: '700', mt: '8px'}}>
                                                    Factuuradres locatie
                                                </Typography>
                                                <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                                    <Checkbox checked={isSameAddress.value} onChange={(e) => {isSameAddress.value = e.currentTarget.checked}}/>
                                                    <Typography variant='bodyLarge'>
                                                        Zelfde als afleveradres
                                                    </Typography>
                                                </Box>
                                                <TextField disabled={isSameAddress.value} required={!isSameAddress.value && isMainDebit.value} label="Postcode" variant="outlined" value={isSameAddress.value ? location_postal_code : debit_postal_code.value} onChange={(e) => {debit_postal_code.value = e.currentTarget.value}}/>
                                                <Split 
                                                    left={
                                                        <TextField disabled={isSameAddress.value} required={!isSameAddress.value && isMainDebit.value} label="Huisnummer" variant="outlined" value={isSameAddress.value ? location_number : debit_number.value} onChange={(e) => {debit_number.value = e.currentTarget.value}}/> 
                                                    }
                                                    right={
                                                        <TextField disabled={isSameAddress.value} label="Toevoeging" variant="outlined" value={isSameAddress.value ? location_number_suffix : debit_number_suffix.value} onChange={(e) => {debit_number_suffix.value = e.currentTarget.value}}/>
                                                    }
                                                />
                                                <TextField disabled={isSameAddress.value} required={!isSameAddress.value && isMainDebit.value} label="Straat" variant="outlined" value={isSameAddress.value ? location_street : debit_street.value} onChange={(e) => {debit_street.value = e.currentTarget.value}}/>
                                                <TextField disabled={isSameAddress.value} required={!isSameAddress.value && isMainDebit.value} label="Plaats" variant="outlined" value={isSameAddress.value ? location_city : debit_city.value} onChange={(e) => {debit_city.value = e.currentTarget.value}}/>
                                            </Box>
                                        </Collapse>
                                    </Box>
                                }
                            />
                        </>
                    }
                    right={
                        <>
                            <Card 
                                title='Status' 
                                children={
                                    <>
                                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography variant='bodyLarge'>
                                                Actief
                                            </Typography>
                                            <Switch onChange={() => {is_active.value = !is_active.value}} checked={is_active.value} />
                                        </Box>
                                        {/* <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography variant='bodyLarge'>
                                                Laatste keer gewijzigd
                                            </Typography>
                                            <Typography variant='bodyLarge'>
                                                01-01-2023 12:00:00
                                            </Typography>
                                        </Box> */}
                                    </>
                                }
                            />
                            <Card 
                                title='Contactgegevens' 
                                children={
                                    <>
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                            <Typography variant='titleMedium' sx={{fontWeight: '700'}}>
                                                Contactgegegevens locatie
                                            </Typography>
                                            <TextField type='email' label="Email" variant="outlined" value={email.value} onChange={(e) => {email.value = e.currentTarget.value}}/>
                                            <TextField label="Telefoonnummer locatie" variant="outlined" value={location_phone_number.value} onChange={(e) => {location_phone_number.value = e.currentTarget.value}}/>
                                        </Box>
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                            <Typography variant='titleMedium' sx={{fontWeight: '700'}}>
                                                Primaire contactpersoon
                                            </Typography>
                                            <TextField label="Voornaam" variant="outlined" value={contact_person_first_name.value} onChange={(e) => {contact_person_first_name.value = e.currentTarget.value}}/>
                                            <Split 
                                                left={
                                                    <TextField label="Tussenvoegsel(s)" variant="outlined" value={contact_person_preposition.value} onChange={(e) => {contact_person_preposition.value = e.currentTarget.value}}/>
                                                } 
                                                right={
                                                    <TextField label="Achternaam" variant="outlined" value={contact_person_last_name.value} onChange={(e) => {contact_person_last_name.value = e.currentTarget.value}}/>
                                                }
                                            />
                                            <TextField label="Telefoonnummer contactpersoon" variant="outlined" value={contact_person_phone_number.value} onChange={(e) => {contact_person_phone_number.value = e.currentTarget.value}}/>
                                        </Box>
                                    </>
                                }
                            />
                             <Card 
                                title='Openingstijden' 
                                children={
                                    <>
                                        <OpeningTimes 
                                            normal={normal_opening_times}
                                            deviant={deviant_opening_times}
                                        />
                                    </>
                                }
                            />
                        </>
                    }
                />
            </form>
        </Box>
      </ThemeProvider>
    );
  }