import { Backdrop, Box, Fade, FormControlLabel, IconButton, Modal, Radio, RadioGroup, Typography } from "@mui/material";
import { Signal } from "@preact/signals-react";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ButtonFull from "../buttons/ButtonFull";

interface ArticleTypeSelectProps {
    open: Signal<boolean>
    openStepper: Signal<boolean>
    selectedProductType: Signal<string>
}

const ArticleTypeSelect: React.FC<ArticleTypeSelectProps> = ({open, openStepper, selectedProductType}) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        outline: 0,
        maxHeight: '80vh',
        overflowY: 'auto'
    };

    return (
        <Modal
            aria-labelledby="confirm-modal"
            aria-describedby="Modal to confirm action"
            open={open.value}
            onClose={() => {open.value = false}}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={open.value}>
                <Box sx={style}>
                    <IconButton sx={{width: 'fit-content', ml: 'auto', mt: '8px', mr: '8px'}} onClick={() => {open.value = false; selectedProductType.value = ''; openStepper.value = false;}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                    <Box sx={{display: 'flex', px: '64px', pb: '64px'}}>
                        <form id='article_type' onSubmit={(e) => {e.preventDefault(); openStepper.value = true;}} style={{display: 'flex', flexDirection: 'column', gap: '32px', width: '100%'}}>
                            <Typography variant='headlineLarge' sx={{mx: 'auto'}}>
                                Artikel toevoegen
                            </Typography>
                            <Typography variant='bodyLarge' >
                                Wat voor artikel wil je toevoegen?
                            </Typography>
                            <RadioGroup
                                value={selectedProductType.value}
                                name="article-type-picker"
                                onChange={(e) => {selectedProductType.value = e.currentTarget.value}}
                            >
                                <FormControlLabel value={'single'} control={<Radio required={selectedProductType.value === ''} />} label={'Los artikel'} />
                                <FormControlLabel value={'compound'} control={<Radio required={selectedProductType.value === ''} />} label={'Samengesteld artikel'} />
                            </RadioGroup>
                            <ButtonFull form='article_type' type='submit' text='Volgende' onClick={() => {}} width='100%'/>
                        </form>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
  };

export default ArticleTypeSelect;