import theme from '../../Theme'
import { ThemeProvider } from '@emotion/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Checkbox, MenuItem, Select, Tab, Typography } from '@mui/material';
import BreakfastDiningOutlinedIcon from '@mui/icons-material/BreakfastDiningOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import EggOutlinedIcon from '@mui/icons-material/EggOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ButtonFull from '../../components/buttons/ButtonFull';
import { useComputed, useSignal, useSignals } from '@preact/signals-react/runtime';
import ArticlesGroupOverview from './ArticlesGroupOverview';
import { batch, Signal } from '@preact/signals-react';
import ArticlesOptionsOverview from './ArticlesOptionsOverview';
import MoreFilters from '../../components/selects/MoreFilters';
import ProductGroupAdd from '../../components/products/ProductGroupAdd';
import { apiClient } from '../../functions/Login';
import { AllergenOut, ProductOptionNaamOut, SalesProductGroupNaamOut } from '../../assets';
import ProductOptionAdd from '../../components/products/ProductOptionAdd';
import ArticlesOverview from './ArticlesOverview';
import ProductAdd from '../../components/products/ProductAdd';

export default function Articles() {
    useSignals();
    const openGroup: Signal<boolean> = useSignal(false);
    const openOption: Signal<boolean> = useSignal(false);
    const openArticle: Signal<boolean> = useSignal(false);
    const groups: Signal<SalesProductGroupNaamOut[]> = useSignal([]);
    const selectedGroups: Signal<string[]> = useSignal([]);
    const allergens: Signal<AllergenOut[]> = useSignal([]);
    const selectedAllergens: Signal<string[]> = useSignal([]);
    const options: Signal<ProductOptionNaamOut[]> = useSignal([]);
    const selectedOptions: Signal<string[]> = useSignal([]);
    const status: Signal<string> = useSignal('');

    const filtering = useComputed(() => {
        return (selectedGroups.value.length > 0 || status.value.length > 0 || selectedAllergens.value.length > 0 || selectedOptions.value.length > 0)
    })

    const clearFilters = () => {
        batch(() => {
           selectedGroups.value = [];
           selectedAllergens.value = [];
           selectedOptions.value = [];
           status.value = '';
        })
    }

    useEffect(() => {
        apiClient().itemManagement.getAllSalesProductGroupsItemsSalesProductGroupsGet().then((r) => {
            groups.value = r.items
        })
        apiClient().itemManagement.getAllAllergensItemsAllergensGet().then((r) => {
            allergens.value = r.items
        })
        apiClient().itemManagement.getAllProductOptionsItemsProductOptionsGet().then((r) => {
            options.value = r.items
        })
    }, [])
    
    const [tab, setTab] = useState<string>('gegevens');

    const param_tab = String(useParams().active_tab);
    const navigate = useNavigate()

    if(param_tab !== "undefined" && param_tab !== tab) {
        if (param_tab === 'opties') status.value = '';
        setTab(param_tab)
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if(tab !== newValue) {
            navigate(`/artikelen/${newValue}`);
        }
    };
    
    const selects = (show_status = true) => 
    <Box sx={{position: 'relative', display: 'flex', gap: '8px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
        <Select
            IconComponent={ExpandMoreOutlinedIcon}
            displayEmpty
            multiple
            value={selectedGroups.value}
            MenuProps={{ style: {maxHeight: '300px'}}}
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return (
                    <Box sx={{display: 'flex', justifyContent: 'start'}}>
                        <CategoryOutlinedIcon />
                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                            Artikelgroep   
                        </Typography> 
                    </Box>)
                }
    
                return groups.value.filter(group => selected.includes(group.id)).map(g => g.sales_product_group_name).join(', ');
            }}
            onChange={(e) => {selectedGroups.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
            sx={{minWidth: '150px', width: '200px', maxWidth: '200px', maxHeight: '50px', borderRadius: '12px'}}
        >
            <MenuItem disabled value='' key=''>
                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                    <CategoryOutlinedIcon />
                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                        Artikelgroep   
                    </Typography> 
                </Box>
            </MenuItem>
            {
                groups.value.map((group) => 
                    <MenuItem value={group.id} key={group.id} >
                        <Checkbox checked={selectedGroups.value.indexOf(group.id) > -1} />
                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                            {group.sales_product_group_name}    
                        </Typography> 
                    </MenuItem>
                )
            }
        </Select>
        <Select
            IconComponent={ExpandMoreOutlinedIcon}
            displayEmpty
            multiple
            value={selectedOptions.value}
            MenuProps={{ style: {maxHeight: '300px'}}}
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return (
                    <Box sx={{display: 'flex', justifyContent: 'start'}}>
                        <BallotOutlinedIcon />
                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                            Artikel opties   
                        </Typography> 
                    </Box>)
                }
    
                return options.value.filter(option => selected.includes(option.id)).map(o => o.product_option_name).join(', ');
            }}
            onChange={(e) => {selectedOptions.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
            sx={{minWidth: '150px', width: '200px', maxWidth: '200px', maxHeight: '50px', borderRadius: '12px'}}
        >
            <MenuItem disabled value='' key=''>
                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                    <BallotOutlinedIcon />
                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                    Artikel opties    
                    </Typography> 
                </Box>
            </MenuItem>
            {
                options.value.map((option) => 
                    <MenuItem value={option.id} key={option.id} >
                        <Checkbox checked={selectedOptions.value.indexOf(option.id) > -1} />
                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                            {option.product_option_name}    
                        </Typography> 
                    </MenuItem>
                )
            }
        </Select>
        <Select
            IconComponent={ExpandMoreOutlinedIcon}
            displayEmpty
            multiple
            value={selectedAllergens.value}
            MenuProps={{ style: {maxHeight: '300px'}}}
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return (
                    <Box sx={{display: 'flex', justifyContent: 'start'}}>
                        <EggOutlinedIcon />
                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                            Allergenen   
                        </Typography> 
                    </Box>)
                }
    
                return selectedAllergens.value.join(', ');
            }}
            onChange={(e) => {selectedAllergens.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
            sx={{minWidth: '150px', width: '200px', maxWidth: '200px', maxHeight: '50px', borderRadius: '12px'}}
        >
            <MenuItem disabled value='' key=''>
                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                    <EggOutlinedIcon />
                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                        Allergenen   
                    </Typography> 
                </Box>
            </MenuItem>
            {
                allergens.value.map((allergen) => 
                    <MenuItem value={allergen.allergen_name} key={allergen.allergen_name} >
                        <Checkbox checked={selectedAllergens.value.indexOf(allergen.allergen_name) > -1} />
                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                            {allergen.allergen_name}    
                        </Typography> 
                    </MenuItem>
                )
            }
        </Select>
        {
            show_status &&
            <Select
                IconComponent={ExpandMoreOutlinedIcon}
                displayEmpty
                value={status.value}
                MenuProps={{ style: {maxHeight: '300px'}}}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return (
                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                            <ToggleOffOutlinedIcon />
                            <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                Status   
                            </Typography> 
                        </Box>)
                    }
                    return selected
                }}
                onChange={(e) => {status.value = e.target.value}}
                sx={{minWidth: '150px', maxWidth: '150px', maxHeight: '50px', borderRadius: '12px'}}
            >
                <MenuItem disabled value='' key=''>
                    <Box sx={{display: 'flex', justifyContent: 'start'}}>
                        <ToggleOffOutlinedIcon />
                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                        Status   
                        </Typography> 
                    </Box>
                </MenuItem>
                <MenuItem value='Actief' key='Actief'>
                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                        Actief   
                    </Typography> 
                </MenuItem>
                <MenuItem value='Inactief' key='Inactief'>
                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                        Inactief   
                    </Typography> 
                </MenuItem>
            </Select>
        }
        <Typography onClick={clearFilters} sx={{display: filtering.value ?  'flex': 'none', cursor: 'pointer', alignSelf: 'center', color: 'secondary.main', borderRadius: '20px', ml:'auto', mr: '8px'}} variant="labelLarge" >
            Filters wissen  
        </Typography> 
    </Box>

    return (
      <ThemeProvider theme={theme}>
        <ProductGroupAdd open={openGroup}/>
        <ProductOptionAdd open={openOption}/>
        <ProductAdd open={openArticle}/>
        <Box sx={{backgroundColor: '#f2f4f4'}}>
            {/* Header with buttons */}
            <Box sx={{margin: '16px', display: 'flex', justifyContent: 'space-between', mr: '32px', gap: '8px'}}>
                <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                    Artikelbeheer
                </Typography> 
                {
                    tab === 'overzicht' ?
                        <>
                            {/* <ButtonOutlined Icon={FileUploadOutlinedIcon} text='Importeer artikelen' onClick={() => {}}/> */}
                            <ButtonFull text='Artikel toevoegen' Icon={AddOutlinedIcon} onClick={() => {openArticle.value = true}}/>
                        </>
                    :
                    tab === 'groepen' ?
                        <>
                              <ButtonFull text='Artikelgroep toevoegen' Icon={AddOutlinedIcon} onClick={() => {openGroup.value = true}}/>
                        </>
                    :
                        <>
                            <ButtonFull Icon={AddOutlinedIcon} text='Optiegroep toevoegen' onClick={() => {openOption.value = true}}/>
                        </>
                }
            </Box>
            <TabContext value={tab}>
                <TabList
                    onChange={handleChange}
                    TabIndicatorProps={{style: {background:'#D18C5C'}}}
                >
                <Tab icon={<BreakfastDiningOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                        Artikelen   
                    </Typography>} value = 'overzicht' 
                />
                <Tab icon={<CategoryOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{ color: 'rgba(0,0,0,0.6)'}}>
                        Groepen  
                    </Typography>} value = 'groepen'
                />
                <Tab icon={<BallotOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{ color: 'rgba(0,0,0,0.6)'}}>
                        Artikel opties   
                    </Typography>} value = 'opties' 
                />
                </TabList>

                <TabPanel value='overzicht' sx={{p: 0}}>
                    <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                        {selects()}
                        <ArticlesOverview 
                            groups={selectedGroups}
                            options={selectedOptions}
                            allergens={selectedAllergens}
                            status={status}
                        />
                    </Box>
                </TabPanel>
                <TabPanel value='groepen' sx={{p: 0}}>
                    <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                        {selects()}
                       <ArticlesGroupOverview 
                            groups={selectedGroups}
                            options={selectedOptions}
                            allergens={selectedAllergens}
                            status={status}
                       />
                    </Box>
                </TabPanel>
                <TabPanel value='opties' sx={{p: 0}}>
                    <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                        {selects(false)}
                        <ArticlesOptionsOverview
                            groups={selectedGroups}
                            options={selectedOptions}
                            allergens={selectedAllergens}
                       />
                    </Box>
                </TabPanel>
            </TabContext>
            </Box>
      </ThemeProvider>
    );
  }