/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_payment_webhook_status_update_payment_payment_facts_payment_webhook_post } from '../models/Body_payment_webhook_status_update_payment_payment_facts_payment_webhook_post';
import type { InvoiceFactIn } from '../models/InvoiceFactIn';
import type { InvoiceFactOut } from '../models/InvoiceFactOut';
import type { InvoiceFactOutList } from '../models/InvoiceFactOutList';
import type { InvoiceIn } from '../models/InvoiceIn';
import type { InvoiceOut } from '../models/InvoiceOut';
import type { InvoiceOutList } from '../models/InvoiceOutList';
import type { PaymentFactIn } from '../models/PaymentFactIn';
import type { PaymentFactOut } from '../models/PaymentFactOut';
import type { PaymentFactOutList } from '../models/PaymentFactOutList';
import type { PaymentIn } from '../models/PaymentIn';
import type { PaymentOrganizationIn } from '../models/PaymentOrganizationIn';
import type { PaymentOrganizationOut } from '../models/PaymentOrganizationOut';
import type { PaymentOrganizationOutList } from '../models/PaymentOrganizationOutList';
import type { PaymentOut } from '../models/PaymentOut';
import type { PaymentOutList } from '../models/PaymentOutList';
import type { PaymentProfileIn } from '../models/PaymentProfileIn';
import type { PaymentProfileOut } from '../models/PaymentProfileOut';
import type { PaymentProfileOutList } from '../models/PaymentProfileOutList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Invoices
     * Get invoices
     * @returns InvoiceOutList Successful Response
     * @throws ApiError
     */
    public getAllInvoicesPaymentInvoicesGet(): CancelablePromise<InvoiceOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/invoices',
        });
    }

    /**
     * Create Invoice
     * Create invoice
     * @param requestBody
     * @returns InvoiceOut Successful Response
     * @throws ApiError
     */
    public createInvoicePaymentInvoicesPost(
        requestBody: InvoiceIn,
    ): CancelablePromise<InvoiceOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/invoices',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Invoice By Id
     * Get invoice
     * @param invoiceId
     * @returns InvoiceOut Successful Response
     * @throws ApiError
     */
    public getInvoiceByIdPaymentInvoicesInvoiceIdGet(
        invoiceId: string,
    ): CancelablePromise<InvoiceOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/invoices/{invoice_id}',
            path: {
                'invoice_id': invoiceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Invoice Facts
     * Get invoice facts
     * @returns InvoiceFactOutList Successful Response
     * @throws ApiError
     */
    public getAllInvoiceFactsPaymentInvoiceFactsGet(): CancelablePromise<InvoiceFactOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/invoice_facts',
        });
    }

    /**
     * Create Invoice Fact
     * Create invoice fact
     * @param requestBody
     * @returns InvoiceFactOut Successful Response
     * @throws ApiError
     */
    public createInvoiceFactPaymentInvoiceFactsPost(
        requestBody: InvoiceFactIn,
    ): CancelablePromise<InvoiceFactOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/invoice_facts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Invoice Fact By Id
     * Get invoice fact by id
     * @param invoiceFactId
     * @returns InvoiceFactOut Successful Response
     * @throws ApiError
     */
    public getInvoiceFactByIdPaymentInvoiceFactsInvoiceFactIdGet(
        invoiceFactId: string,
    ): CancelablePromise<InvoiceFactOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/invoice_facts/{invoice_fact_id}',
            path: {
                'invoice_fact_id': invoiceFactId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Payment Organizations
     * Get all payment organizations
     * @returns PaymentOrganizationOutList Successful Response
     * @throws ApiError
     */
    public getAllPaymentOrganizationsPaymentPaymentOrganizationsGet(): CancelablePromise<PaymentOrganizationOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/payment_organizations',
        });
    }

    /**
     * Create Payment Organization
     * Create payment organization
     * @param requestBody
     * @param dryRun
     * @returns PaymentOrganizationOut Successful Response
     * @throws ApiError
     */
    public createPaymentOrganizationPaymentPaymentOrganizationsPost(
        requestBody: PaymentOrganizationIn,
        dryRun?: (boolean | null),
    ): CancelablePromise<PaymentOrganizationOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/payment_organizations',
            query: {
                'dry_run': dryRun,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payment Organization By Id
     * Get payment organization by id
     * @param paymentOrganizationId
     * @returns PaymentOrganizationOut Successful Response
     * @throws ApiError
     */
    public getPaymentOrganizationByIdPaymentPaymentOrganizationsPaymentOrganizationIdGet(
        paymentOrganizationId: string,
    ): CancelablePromise<PaymentOrganizationOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/payment_organizations/{payment_organization_id}',
            path: {
                'payment_organization_id': paymentOrganizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Payment Organization
     * Delete payment organization
     * @param paymentOrganizationId
     * @returns void
     * @throws ApiError
     */
    public deletePaymentOrganizationPaymentPaymentOrganizationsPaymentOrganizationIdDelete(
        paymentOrganizationId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/payment/payment_organizations/{payment_organization_id}',
            path: {
                'payment_organization_id': paymentOrganizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payment Organization Auth Url
     * Retrieve auth redirect url for organization
     * @param paymentOrganizationId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getPaymentOrganizationAuthUrlPaymentPaymentOrganizationsPaymentOrganizationIdAuthUrlGet(
        paymentOrganizationId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/payment_organizations/{payment_organization_id}/auth_url',
            path: {
                'payment_organization_id': paymentOrganizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Trigger Payment Organization Auth Flow
     * Trigger authorization flow for organization
     * @param state
     * @param code
     * @param error
     * @param dryRun
     * @returns void
     * @throws ApiError
     */
    public triggerPaymentOrganizationAuthFlowPaymentPaymentOrganizationsAuthWebhookGet(
        state: string,
        code: string,
        error?: (string | null),
        dryRun?: (boolean | null),
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/payment_organizations_auth_webhook',
            query: {
                'state': state,
                'code': code,
                'error': error,
                'dry_run': dryRun,
            },
            errors: {
                308: `Successful Response`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Payment Profiles
     * Get all payment profiles
     * @returns PaymentProfileOutList Successful Response
     * @throws ApiError
     */
    public getAllPaymentProfilesPaymentPaymentProfilesGet(): CancelablePromise<PaymentProfileOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/payment_profiles',
        });
    }

    /**
     * Create Payment Profile
     * Create payment profile
     * @param requestBody
     * @param dryRun
     * @returns PaymentProfileOut Successful Response
     * @throws ApiError
     */
    public createPaymentProfilePaymentPaymentProfilesPost(
        requestBody: PaymentProfileIn,
        dryRun?: (boolean | null),
    ): CancelablePromise<PaymentProfileOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/payment_profiles',
            query: {
                'dry_run': dryRun,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payment Profile By Id
     * Get payment profile by id
     * @param paymentProfileId
     * @returns PaymentProfileOut Successful Response
     * @throws ApiError
     */
    public getPaymentProfileByIdPaymentPaymentProfilesPaymentProfileIdGet(
        paymentProfileId: string,
    ): CancelablePromise<PaymentProfileOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/payment_profiles/{payment_profile_id}',
            path: {
                'payment_profile_id': paymentProfileId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Payments
     * Get all payments
     * @returns PaymentOutList Successful Response
     * @throws ApiError
     */
    public getAllPaymentsPaymentPaymentsGet(): CancelablePromise<PaymentOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/payments',
        });
    }

    /**
     * Create Payment
     * Create payment
     * @param requestBody
     * @param dryRun
     * @returns PaymentOut Successful Response
     * @throws ApiError
     */
    public createPaymentPaymentPaymentsPost(
        requestBody: PaymentIn,
        dryRun?: (boolean | null),
    ): CancelablePromise<PaymentOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/payments',
            query: {
                'dry_run': dryRun,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payment By Id
     * Get payment by id
     * @param paymentId
     * @returns PaymentOut Successful Response
     * @throws ApiError
     */
    public getPaymentByIdPaymentPaymentsPaymentIdGet(
        paymentId: string,
    ): CancelablePromise<PaymentOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/payments/{payment_id}',
            path: {
                'payment_id': paymentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refund Payment
     * Refund payment, don't use for frontend calls
     * @param extPaymentId
     * @param dryRun
     * @returns PaymentOut Successful Response
     * @throws ApiError
     */
    public refundPaymentPaymentPaymentsExtPaymentIdRefundGet(
        extPaymentId: string,
        dryRun?: (boolean | null),
    ): CancelablePromise<PaymentOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/payments/{ext_payment_id}/refund',
            path: {
                'ext_payment_id': extPaymentId,
            },
            query: {
                'dry_run': dryRun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Payment Facts
     * Get all payment facts
     * @returns PaymentFactOutList Successful Response
     * @throws ApiError
     */
    public getAllPaymentFactsPaymentPaymentFactsGet(): CancelablePromise<PaymentFactOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/payment_facts',
        });
    }

    /**
     * Create Payment Fact
     * Create payment fact
     * @param requestBody
     * @returns PaymentFactOut Successful Response
     * @throws ApiError
     */
    public createPaymentFactPaymentPaymentFactsPost(
        requestBody: PaymentFactIn,
    ): CancelablePromise<PaymentFactOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/payment_facts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payment Fact By Id
     * Get payment fact by id
     * @param paymentFactId
     * @returns PaymentFactOut Successful Response
     * @throws ApiError
     */
    public getPaymentFactByIdPaymentPaymentFactsPaymentFactIdGet(
        paymentFactId: string,
    ): CancelablePromise<PaymentFactOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/payment_facts/{payment_fact_id}',
            path: {
                'payment_fact_id': paymentFactId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payment Webhook Status Update
     * Endpoint specifically for passing the payment provider a webhook url for them to call on status changes.
     * This triggers the status retrieval process in our backend.
     * !!This endpoint has no follow up on module specific logic!!
     * @param formData
     * @param dryRun
     * @returns any Successful Response
     * @throws ApiError
     */
    public paymentWebhookStatusUpdatePaymentPaymentFactsPaymentWebhookPost(
        formData: Body_payment_webhook_status_update_payment_payment_facts_payment_webhook_post,
        dryRun?: (boolean | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/payment_facts/payment_webhook',
            query: {
                'dry_run': dryRun,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Payment Status By Id
     * This triggers the status retrieval process in our backend based on internal payment id.
     * @param paymentId
     * @param dryRun
     * @returns PaymentFactOut Successful Response
     * @throws ApiError
     */
    public updatePaymentStatusByIdPaymentPaymentFactsUpdatePaymentStatusByIdPaymentIdGet(
        paymentId: string,
        dryRun?: (boolean | null),
    ): CancelablePromise<PaymentFactOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/payment_facts/update_payment_status_by_id/{payment_id}',
            path: {
                'payment_id': paymentId,
            },
            query: {
                'dry_run': dryRun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Payment Status By External Id
     * This triggers the status retrieval process in our backend based on external payment id.
     * @param extPaymentId
     * @param dryRun
     * @returns PaymentFactOut Successful Response
     * @throws ApiError
     */
    public updatePaymentStatusByExternalIdPaymentPaymentFactsUpdatePaymentStatusByExternalIdExtPaymentIdGet(
        extPaymentId: string,
        dryRun?: (boolean | null),
    ): CancelablePromise<PaymentFactOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/payment_facts/update_payment_status_by_external_id/{ext_payment_id}',
            path: {
                'ext_payment_id': extPaymentId,
            },
            query: {
                'dry_run': dryRun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
